import React from 'react';

const GenerateToken = (props)=>{
	return(
		<div className = 'generateToken box'>
			<h3>Generate Token</h3>
			<input className = 'button' type = 'submit' value = 'Generate' onClick = {props.generateToken}/>
			<p className = 'token'>{props.token}</p>
		</div>
	)
}
export default GenerateToken;