import React, { useEffect, useState } from 'react';
import Particles from 'react-particles-js';
import { PDFDownloadLink } from '@react-pdf/renderer';
import logo from '../../images/wsLogo.jpg';
import { ReactComponent as Related1 } from '../../svg/undraw_book_lover_mkck.svg';
import { ReactComponent as Related2 } from '../../svg/undraw_dev_productivity_umsq.svg';
import { ReactComponent as Related3 } from '../../svg/undraw_reading_list_4boi.svg';
import UtmePdfDocument from '../../UtmeComponents/UtmePdfDocument';
import UtmeExam from '../../UtmeComponents/UtmeExam/UtmeExam';
import UtmeLoading from '../../UtmeComponents/UtmeLoading/UtmeLoading';
import './UtmeDashboard.css';

const orderQuestion = (li)=>{
	let QuestionList = [];
	let subList = [];
	li.forEach((question, i)=>{
		question.sAnswer = '';
		question.answered = false;
		subList.push(question);
		if((i+1) % 10 === 0){
			QuestionList.push(subList)
			subList = []
		}
	})
	if(subList[0]){
		QuestionList.push(subList)
	}
	return QuestionList;
}

const shuffleQuestion = async (questionArray)=>{
    let done = false
    for(let i = questionArray.length - 1; i > 0; i--){
        const j = Math.floor(Math.random()*i)
        const temp = questionArray[i]
        questionArray[i] = questionArray[j]
        questionArray[j] = temp
        if(i===1){
            done = true
        }
    }

    if(done){
        return orderQuestion(questionArray)
    }
}

const UtmeDashboard = ({ studentDetails, setStudentDetails, logOut })=>{
    const [utmePage, setUtmePage] = useState("Home")
    const [formattedCurrentTime, setFormattedCurrentTime] = useState();
    const [intervalId, setIntervalId] = useState();
    const [timeComplete, setTimeComplete] = useState(false);
    const [examOpened, setExamOpened] = useState(false);
    const [PdfReady, setPdfReady] = useState(false);
    const [loading, setLoading] = useState(false);
    const [questionList, setQuestionList] = useState();
    const examDate = new Date(2021, 9, 29, 12, 0, 0).getTime();


    const particlesOption = {
        "particles": {
            "number": {
              "value": 100,
              "density": {
                "enable": true,
                "value_area": 600
              }
            },
            "color": {
              "value": "#ffffff"
            },
            
            "opacity": {
              "value": 0.5,
              "random": false,
              "anim": {
                "enable": false,
                "speed": 1,
                "opacity_min": 0.1,
                "sync": false
              }
            },
            "size": {
              "value": 5,
              "random": true,
              "anim": {
                "enable": false,
                "speed": 40,
                "size_min": 0.1,
                "sync": false
              }
            },
            "line_linked": {
              "enable": true,
              "distance": 150,
              "color": "#ffffff",
              "opacity": 0.4,
              "width": 1
            },
            "move": {
              "enable": true,
              "speed": 6,
              "direction": "none",
              "random": false,
              "straight": false,
              "out_mode": "out",
              "attract": {
                "enable": false,
                "rotateX": 600,
                "rotateY": 1200
              }
            }
          }
    }  

    const onDashboardPageChange = (page)=>{
        window.scrollTo({top: 0, behaviour: 'smooth'});
        setUtmePage(page)
    }

    const onStartExam = ()=>{
        if(studentDetails.exam_access){
            setLoading(true)
            fetch(`${process.env.REACT_APP_API_URL}/question/getQuestions`)
            .then(res => res.json())
            .then(async (questionArray)=>{
            const shuffledQuestionList = await shuffleQuestion(questionArray)
            setQuestionList(shuffledQuestionList)
            setLoading(false)
            onDashboardPageChange("Exam")
            })
            .catch((error)=>{
                console.log(error)
                setLoading(false)
                alert("An error occured")
            })
        }else{
            alert("no more attempt for this user")
        }
    }

    const onFinishExam = ()=>{
        setLoading(true)
        fetch(`${process.env.REACT_APP_API_URL}/student/studentProfile/${studentDetails.student_id}`)
            .then(res=> res.json())
            .then(data=> {
                if(data.student_id){
                    setStudentDetails(data)
                    setLoading(false)
                    onDashboardPageChange("Home")
                }else{
                    alert("an error occured")
                    setLoading(false)
                }
            })
    }

    const displayPage = ()=>{
        switch(utmePage){
            case "Home":
                return(
                    <div>
                        <div className = "Utme_header">
                            <div className = "Utme_header-logo-wrapper">
                                <img className = "Utme_header-logo"src={logo} alt ='logo'/>
                                <p className = "Utme_header-logo-text">Waltersam Services</p>
                            </div>
                            <p
                                className = "Utme_header-text"
                                onClick = {()=>{
                                    clearInterval(intervalId); 
                                    logOut()
                                }}
                            >
                                Log Out
                            </p>
                        </div>
                        <div className = "Utme_dash_sec1">
                            <Particles
                                className = "particles"
                                params = {particlesOption}
                            
                            />
                            <div className = "welcome-message">
                                <p className = "welcome-text">welcome,</p>
                                <p className = "welcome-first_name">{studentDetails.firstname}</p>
                                <p className = "welcome-reg_number">{studentDetails.reg_number}</p>
                            </div>
                            <div className = "countdown_sec">
                                <p className = "countdown-label">Mock Examination</p>
                                <p className = "countdown-label">In</p>
                                <p className = "countdown-timer">{formattedCurrentTime}</p>
                                <div className = "countdown-timer-label">
                                    <p className = "countdown-timer-label-days">Days</p>
                                    <p className = "countdown-timer-label-hours">Hours</p>
                                    <p className = "countdown-timer-label-minutes">Minutes</p>
                                    <p className = "countdown-timer-label-seconds">Seconds</p>
                                </div>
                            </div>
                        </div>
                        <div className = "Utme_dash_sec2">
                            <div className = "features">
                                <ul>
                                    <li>Examination standard Computer Based Test</li>
                                    <li>Integrated Timer</li>
                                    <li>Question Tracker for Great Examination experience</li>
                                    <li>Result will be available in PDF Format</li>
                                    <li>Lots of Prizes to be won for Execellent Performance</li>
                                </ul>
                            </div>
                            <div className = "congrat-text">
                                CONGRATULATIONS! You are at the best platform for UNIVERSITY OF LAGOS POST UTME Mock Examination
                            </div>
                            {
                                examOpened &&
                                <div className = "exam-instructions">
                                    <div className = 'subject-details'>
                                        <p>Exam duration: 30 Minutes</p>
                                        <p>Number Of Questions: 40</p>
                                        <p>Your timer starts immediately you start the exam. Get ready before starting. GOODLUCK!</p>
                                    </div>
                                    <div className = 'exam-start-link'>
                                        <p onClick={onStartExam}>CLICK HERE TO START EXAM</p>
                                    </div>
                                </div>
                            }
                            {
                                (PdfReady && studentDetails.result_id) &&
                                <div className = "exam-instructions">
                                    <PDFDownloadLink
                                        document = {<UtmePdfDocument student = {studentDetails}/>}
                                        fileName = 'WALTERSAM POST UTME MOCK RESULT.pdf'
                                        style = {{
                                            padding: '5px 20px',
                                            textDecoration: 'none',
                                            backgroundColor: '#27ADB0',
                                            color: '#ffffff',
                                            outline: 'none',
                                            border: 'none',
                                            borderRadius: '15px',
                                            marginBottom: '10px',
                                            fontSize: '17px'
                                        }}
                                    >
                                        {({ blob, url, ploading, error }) => (ploading ? 'Loading document...' : 'Download Result')}
                                    </PDFDownloadLink>
                                </div>
                            }
                            <div className = "related-images">
                                <Related1 className = "related-image"/>
                                <Related2 className = "related-image"/>
                                <Related3 className = "related-image"/>
                            </div>
                            <div className = "exam-disclaimer">
                                <p>This platform gives students an opportunity to evaluate the 
                                    level of their preparation before taking the actual examination.
                                    The questions provided are examination standard questions prepared by scholars and seasoned tutors.
                                    The questions provided are NOT what you will see in the main examination.
                                    Waltersam Services will not be responsible for any false claims
                                </p>
                            </div>
                        </div>
                    </div>
                )
            case "Exam":
                return(
                    <UtmeExam 
                        student = {studentDetails} 
                        dashboardChange = {onFinishExam}
                        propsQuestionList = {questionList}
                    />
                )
            default:
                return <p>No page to display</p>
        }
    }

    useEffect(()=>{
        if(intervalId === undefined){
            const x = setInterval(()=>{ 
                const now = new Date().getTime(); 
                const time = examDate - now; 
                const days = Math.floor(time / (1000 * 60 * 60 * 24)) > 9 ? Math.floor(time / (1000 * 60 * 60 * 24)) : "0"+Math.floor(time / (1000 * 60 * 60 * 24)); 
                const hours = Math.floor((time %(1000 * 60 * 60 * 24))/(1000 * 60 * 60)) > 9 ? Math.floor((time %(1000 * 60 * 60 * 24))/(1000 * 60 * 60)) : "0"+Math.floor((time %(1000 * 60 * 60 * 24))/(1000 * 60 * 60)); 
                const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60)) > 9 ? Math.floor((time % (1000 * 60 * 60)) / (1000 * 60)) : "0"+Math.floor((time % (1000 * 60 * 60)) / (1000 * 60)); 
                const seconds = Math.floor((time % (1000 * 60)) / 1000) > 9 ? Math.floor((time % (1000 * 60)) / 1000) : "0"+Math.floor((time % (1000 * 60)) / 1000);
                setFormattedCurrentTime(`${days} : ${hours} : ${minutes} : ${seconds}`)
        
                if ((time/1000) < 1) {
                    clearInterval(x)
                    setTimeComplete(true)
                    setFormattedCurrentTime(`00 : 00 : 00 : 00`)
                }
            }, 1000);

            setIntervalId(x)
        }

    // eslint-disable-next-line
    }, [intervalId])

    useEffect(()=>{
        if(timeComplete && studentDetails.exam_access){
            setExamOpened(true)
            setPdfReady(false)
        }else{
            setExamOpened(false)
            setPdfReady(true)
        }

    // eslint-disable-next-line
    }, [timeComplete, studentDetails.exam_access])
    return(
        <div>
            {
                loading &&
                <UtmeLoading/>
            }
            {
                displayPage()
            }
        </div>
    )
}

export default UtmeDashboard;