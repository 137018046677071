import React, {useState} from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from "react-router-dom";
import './UtmeLogin.css';
import user from '../../images/login.jpg';

const UtmeLogin = ({ logStudentIn })=>{
	const [loginError, setLoginError] = useState()

	const history = useHistory()
    const {
        register,
        handleSubmit
    } = useForm()

	const focus = (event)=>{
		const parent = event.target.parentNode;
		parent.classList.add('focus');
	}

	const blur = (event)=>{
		let parent = event.target.parentNode;
		if(event.target.value === ""){
			parent.classList.remove('focus');
		}
	}

	const onLogin = (formData)=>{
		window.scrollTo({top: 0, behaviour: 'smooth'})
		setLoginError("")
		fetch(`${process.env.REACT_APP_API_URL}/auth/login`, {
			method: 'post',
			headers: {'content-Type': 'application/json'},
			body: JSON.stringify(formData)
		})
		.then(res=> res.json())
		.then(data=> {
			if(data.student_id){
				logStudentIn(data)
				history.push('/postutme/dashboard')
			}else{
				setLoginError('Incorrect Registration number or password')
			}
		})
		.catch(err=> console.log('unable to access server'))
	}

	return(
		<div>
			<div className = 'curved-header'>
				<div>
					<p className = 'hTxt'><b>WALTERSAM SERVICES</b></p>
					<p className = 'sTxt'>UNILAG POST UTME MOCK EXAMINATION</p>
				</div>
			</div>
			<div className = 'login'>
				<div>
					<div className = 'login-header'>
						<img src={user} alt = 'user'/>
					</div>
					<div className = 'login-container'>
						<form onSubmit = {handleSubmit(onLogin)}>
							<p className = 'error'>{loginError}</p>
							<p className = 'success'></p>
							<div className = 'input-div'>
								<input 
									onFocus = {focus}
									onBlur = {blur}
									className = 'input' 
									type = 'text'
									name = 'reg_number'
									placeholder = 'Jamb Registration Number'
									ref = {register({
                                        required: "required"
                                    })}
								/>
							</div>
							<div className = 'input-div'>
								<input 
									onFocus = {focus}
									onBlur = {blur}
									className = 'input' 
									type = 'password'
									name = 'password'
									placeholder = 'password'
									ref = {register({
                                        required: "required"
                                    })}
								/>
							</div>
							<div>
								<input className = 'btn' type='submit' value ='Login'/>
							</div>
							<a href = "https://api.whatsapp.com/send?phone=2348097550815&text=Hi%2C%20I%20am%20a%20registered%20student%20of%20Waltersam%20Services%20post%20utme%20mock%20and%20I%20want%20to%20reset%20my%20password.">forgot password?</a>
						</form>
						<div className = 'reg'>
							<p className = 'ques'>Not Registered?</p>
							<button 
								className = 'btn'
								onClick = {()=>{
									window.scrollTo({top: 0, behaviour: 'smooth'})
									history.push('/postutme/register')
								}}
							>
								Register
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default UtmeLogin;