import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import Verify from './UtmeVerify';
import './UtmeRegister.css'


const UtmeRegister = ()=>{
    const [registerPage, setRegisterpage] = useState("verify");
    const [registerError, setRegisterError] = useState();
    const [verifyError, setVerifyError] = useState();

    const history = useHistory();

    const {
        register,
        handleSubmit,
        errors,
        watch
    } = useForm()

    const password = useRef({});
    password.current = watch('password', '');
    
    const onSubmitVerify = (formData)=>{
        setVerifyError("")
        fetch(`${process.env.REACT_APP_API_URL}/auth/verfiyToken`, {
			method: 'post',
			headers: {'content-Type': 'application/json'},
			body: JSON.stringify(formData)
		})
		.then(res=> res.json())
		.then(data=> {
			if(data === 'success'){
                setRegisterpage("register")
			}else{
				setVerifyError("Invalid or expired token")
			}
		})
    }
  
    const onSubmitRegister = (formData)=>{
        window.scrollTo({top: 0, behaviour: 'smooth'})
        setRegisterError("")
        fetch(`${process.env.REACT_APP_API_URL}/auth/register`, {
			method: 'post',
			headers: {'content-Type': 'application/json'},
			body: JSON.stringify(formData)
		})
		.then(res=> res.json())
		.then(data=> {
			if(data === 'success'){
                history.push({
                    pathname: './postutme/login',
                    remes: 'Registration successful. You can now login',
                })
			}else{
				setRegisterError(data)
			}
		})
		.catch(err=> console.log('unable to access server'))
    }
    
    return(
        <div>
            <div className = 'curved-header'>
				<div>
					<p className = 'hTxt'><b>WALTERSAM SERVICES</b></p>
					<p className = 'sTxt'>UNILAG POST UTME MOCK EXAMINATION</p>
				</div>
			</div>
            <div className = 'studentRegister'>
            {
                registerPage === "register" ?
                (
                    <div>
                        <h3 className = 'register-header'>Register</h3>
                        <div className = 'container'>
                        <p className = 'error'>{registerError}</p>
                            <form onSubmit = {handleSubmit(onSubmitRegister)}>
                                <input 
                                    className = 'text' 
                                    name = 'lastname'  
                                    placeholder = 'Last Name' 
                                    ref = {register({
                                        required: "Lastname is required"
                                    })}    
                                /><br/>
                                {errors.lastname && <h6 className = 'vError'>{errors.lastname.message}</h6>}
                                <br/>
                                <input 
                                    className = 'text' 
                                    name = 'firstname'  
                                    placeholder = 'First Name' 
                                    ref = {register({
                                        required: "Firstname is required"
                                    })}
                                /><br/>
                                {errors.firstname && <h6 className = 'vError'>{errors.firstname.message}</h6>}
                                <br/>
                                <input 
                                    className = 'text' 
                                    name = 'reg_number'  
                                    placeholder = 'Jamb Registration Number' 
                                    ref = {register({
                                        required: "required"
                                    })}
                                /><br/>
                                {errors.reg_number && <h6 className = 'vError'>{errors.reg_number.message}</h6>}
                                <br/>
                                <input 
                                    className = 'text' 
                                    name = 'password'  
                                    placeholder = 'Password'
                                    type = 'password'
                                    ref = {register({
                                        required: "Provide a Password"
                                    })}
                                /><br/>
                                {errors.password && <h6 className = 'vError'>{errors.password.message}</h6>}
                                <br/>
                                <input 
                                    className = 'text' 
                                    name = 'confirm_password'  
                                    placeholder = 'Confirm Password' 
                                    type = 'password'
                                    ref={register({
                                        required: 'You must re-enter your password',
                                        validate: (value) =>
                                            value === password.current || 'The passwords do not match',
                                        })}
                                /><br/>
                                {errors.confirm_password && <h6 className = 'vError'>{errors.confirm_password.message}</h6>}
                                <br/>
                                <input className = 'button' type = 'submit' value = 'Register'/><br/>
                            </form>
                        </div>
                    </div>
                ):
                (
                    <Verify onSubmitVerify = {onSubmitVerify} verifyError = {verifyError}/>
                )
            }
            </div>
			
        </div>
    )
}

export default UtmeRegister;