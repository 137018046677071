import React from 'react';
import logo from '../../../images/wsLogo.jpg';

const AdminHeading = (props)=>{
	return(
		<div>
			<div className = 'header'>
				<img src={logo} alt ='logo'/>
				<p className = 'header-text'>Admin</p>
				<p className='logout' onClick = {()=>{
					props.onRouteChange('AdminLogin')
				}}>Log Out</p>
			</div>
			<div className = 'nav'>
				<p onClick = {()=>{
					props.changePage('generateToken')
				}
				}>Generate token</p>
				<p onClick = {()=>{
						props.changePage('register')
					}
				}>Register</p>
				<p onClick = {()=>{
						props.changePage('studentDetails')
					}
				}>Student details</p>
				<p onClick = {()=>{
						props.changePage('allStudents')
					}
				}>All students</p>
				<p onClick = {()=>{
						props.changePage('changePassword')
					}
				}>Change password</p>
				<p onClick = {()=>{
						props.changePage('deleteStudent')
					}
				}>Delete student</p>
				<p onClick = {()=>{
						props.changePage('uploadCourse')
					}
				}>Upload course</p>
				<p onClick = {()=>{
						props.changePage('deleteCourse')
					}
				}>Delete course</p>
				<p onClick = {()=>{
						props.changePage('uploadQuestion')
					}
				}>Upload question</p>
				<p onClick = {()=>{
						props.changePage('examCourse')
					}
				}>Exam course</p>
				<p onClick = {()=>{
						props.changePage('result')
					}
				}>Result</p>
				<p onClick = {()=>{
						props.updateGPA()
					}
				}>Update GPA</p>
			</div>
			<p className = 'error'>{props.error}</p>
		</div>
	);
}

export default AdminHeading;