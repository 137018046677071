import React from 'react';
import './studentRegister.css';
import Register from './register';
import Verify from './verify';

class StudentRegister extends React.Component{
	constructor(props){
		super(props)
		this.state = {
			registerResponse: '',
			courseList : [],
			registerRoute: "Verify"
		}
	}

	componentDidMount(){
		fetch('https://ws-mock-api.herokuapp.com/courses')
		.then((res)=> res.json())
		.then(data =>{
			this.setState(state=>{
				const courseList = data.map((courseObj)=>{
					return courseObj.code
				})
				return {courseList}
			})
		})
		.catch((err)=> console.log('unable to access server'))
	}

	registerRouteChange = (route)=>{
		this.setState({registerRoute: route})
	}
	
	

	render(){
		if(this.state.registerRoute === "Verify"){
			return(
				<Verify registerRouteChange = {this.registerRouteChange}/>
			)
		}else if(this.state.registerRoute === "Register"){
			return(
				<Register 
					courseList = {this.state.courseList}
					onRouteChange = {this.props.onRouteChange}
					regSuccess = {this.props.regSuccess}
				/>
			)
		}	
	}
	
}

export default StudentRegister;