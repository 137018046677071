import React from 'react';
import {Page, Text, View, Document, StyleSheet, Image} from '@react-pdf/renderer';
import logo from '../images/wsServicesLogo.jpg';


const styles = StyleSheet.create({
	page: {
		marginTop: 20,
		marginBottom: 20,
		marginLeft: 25,
		marginRight: 25,
		backgroundColor: '#ffffff',
		fontSize: 12
	},
	container: {
		width: 550,
	},
	result_table: {
		display: 'flex',
		flexDirection: 'row',
		
	},
	table_content: {
		width: 250,
		marginBottom: 10
	},
	header: {
		alignItems: 'center',
		fontWeight: 'normal',
		marginBottom: 30,
	},
	logo: {
		height: 80,
		width: 80,
		borderRadius: 50
	},
	main: {
		margin: 0,
	  	fontSize: 15
	},
	sub: {
		margin: 0,
	  	fontSize: 10
	},
	details: {
	  backgroundColor: '#27ADB0',
	  color: '#FFF',
	  marginTop: 10,
	  marginBottom: 10,
	  paddingTop: 2,
	  paddingBottom: 2,
	  paddingLeft: 4,
	  paddingRight: 4
	},
	content: {
		marginLeft: 10
	}

})
const UtmePdfDocument = ({ student })=>{
	return(
		<Document>
			<Page size = "A4" style = {styles.page}>
				<View style = {styles.container}>
					<View style = {styles.header}>
						<Image source = {logo} style = {styles.logo}/>
						<Text style = {styles.main}>WALTERSAM SERVICES</Text>
						<Text style = {styles.sub}>POST UTME MOCK EXAMINATON RESULT</Text>
					</View>
					<View>
						<Text style = {styles.main} >SCORE: {student.score}</Text>
					</View>
					<View>
						<Text style = {styles.details}>Registration number</Text>
						<Text style = {styles.content}>{student.reg_number}</Text>
						<Text style = {styles.details}>Lastname</Text>
						<Text style = {styles.content}>{student.lastname}</Text>
						<Text style = {styles.details}>Firstname</Text>
						<Text style = {styles.content}>{student.firstname}</Text>
						
						
						<Text style = {styles.details}>Examination details</Text>
						<View style = {styles.content}>
							<View style = {styles.result_table}>
								<Text style = {styles.table_content}>Number Of Questions :</Text>
								<Text style = {styles.table_content}>40</Text>
							</View>
							<View style = {styles.result_table}>
								<Text style = {styles.table_content}>Number Of Questions Answered :</Text>
								<Text style = {styles.table_content}>{student.no_answered}</Text>
							</View>
							<View style = {styles.result_table}>
								<Text style = {styles.table_content}>Number Of Question Answered Correctly :</Text>
								<Text style = {styles.table_content}>{student.no_correctly}</Text>
							</View>
							<View style = {styles.result_table}>
								<Text style = {styles.table_content}>Time Spent :</Text>
								<Text style = {styles.table_content}>{student.time_taken}</Text>
							</View>
							<View style = {styles.result_table}>
								<Text style = {styles.table_content}>Score(over 30) :</Text>
								<Text style = {styles.table_content}>{student.score}</Text>
							</View>
						</View>
					</View>
				</View>
			</Page>
		</Document>
	)
}

export default UtmePdfDocument;