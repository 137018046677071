import React from 'react';

const Register = (props)=>{
	return(
		<div className = 'register box'>
			<h3>Register Student</h3>
			<div>
				<input className = 'text' type = 'text' name = 'matric' placeholder = 'Matric number' /><br/><br/>
				<input className = 'text' type = 'text' name = 'password'  placeholder = 'Password' /><br/><br/>
				<input className = 'text' type = 'text' name = 'lastname'  placeholder = 'Lastname' /><br/><br/>
				<input className = 'text' type = 'text' name = 'firstname'  placeholder = 'Firstname' /><br/><br/>
				<input className = 'text' type = 'text' name = 'faculty'  placeholder = 'Faculty' /><br/><br/>
				<input className = 'text' type = 'text' name = 'department'  placeholder = 'Department' /><br/><br/>
				<p>Courses</p>
				<div name = 'courses'>
					{
						props.courseList.map((course, i)=>{
							return(
								<div key = {i}>
									<input 
										type = 'checkbox' 
										name = {course} 
									/>
									<label htmlFor = {course}>{course}</label><br/>
								</div>
							) 
						})
					}
				</div>
				<br/>
				<input className = 'button' type = 'submit' value = 'Register' onClick = {props.register}/><br/>
			</div>
		</div>

	)
}

export default Register;