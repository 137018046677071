import React, { useState } from 'react';
import UtmeAdminHeading from '../../UtmeComponents/UtmeAdminHeading/UtmeAdminHeading';
import {useForm} from 'react-hook-form';

const UtmeAdminDashboard = ({ logOut })=>{
    const [adminPage, setAdminPage] = useState("generateToken");
    const [adminError, setAdminError] = useState()
    const [token, setToken] = useState()
    const [studentList, setStudentList] = useState([])
    const [resultList, setResultList] = useState([])
    
    const { register, handleSubmit} = useForm()
    const onAdminPageChange = (page)=>{
        setAdminPage(page)
    }

    const generateToken = ()=>{
        setToken("")
        fetch(`${process.env.REACT_APP_API_URL}/auth/generateToken`)
		.then(res=> res.json())
		.then(data=> {
			if(data !== 'there was an error'){
				setToken(data)
			}else{
				setAdminError('error')
			}
		})
		.catch(err=> console.log('unable to access server'))
    }

    const getAllStudents = ()=>{
        setStudentList([])
        fetch(`${process.env.REACT_APP_API_URL}/student/allStudents`)
		.then(res=> res.json())
		.then(data=> {
			if(data !== 'there was an error'){
				setStudentList(data)
			}else{
				setAdminError('error')
			}
		})
		.catch(err=> console.log('unable to access server'))
    }

    const getStudentResults = ()=>{
        setResultList([])
        fetch(`${process.env.REACT_APP_API_URL}/student/getResults`)
		.then(res=> res.json())
		.then(data=> {
			if(data !== 'no result available'){
				setResultList(data)
			}else{
				setAdminError('error')
			}
		})
		.catch(err=> console.log('unable to access server'))
    }

    const changePassword = (formData)=>{
        setAdminError()
        fetch(`${process.env.REACT_APP_API_URL}/auth/changePassword`, {
            method: 'post',
            headers: {'content-Type': 'application/json'},
            body: JSON.stringify(formData)
        })
        .then(res=> res.json())
        .then(data=>{
            setAdminError(data)
        })
        .catch(err=> console.log('unable to access server'))
    }

    const deleteStudent = (formData)=>{
        setAdminError()
        fetch(`${process.env.REACT_APP_API_URL}/student/deleteStudent`, {
			method: 'delete',
			headers: {'content-Type': 'application/json'},
			body: JSON.stringify(formData)
		})
		.then(res=> res.json())
		.then(data=> {
			setAdminError(data)
		})
		.catch(err=> console.log('unable to access server'))
    }

    const uploadQuestion = (formData)=>{
        setAdminError()
        fetch(`${process.env.REACT_APP_API_URL}/question/uploadQuestion`, {
            method: 'post',
            headers: {'content-Type': 'application/json'},
            body: JSON.stringify(formData)
        })
        .then(res=> res.json())
        .then(data=>{
            setAdminError(data)
        })
        .catch(err=> console.log('unable to access server'))
    }

    const displayCurrentView = ()=>{
        switch(adminPage){
            case "generateToken":
                return(
                    <div className = 'generateToken box'>
                        <h3>Generate Token</h3>
                        <input className = 'button' type = 'submit' value = 'Generate' onClick = {generateToken}/>
                        <p className = 'token'>{token}</p>
                    </div>
                )
            case "studentResults":
                return(
                    <div className = 'all-students box'>
                        <h3>Student Results</h3>
                        <input className = 'button' type = 'submit' value = 'View' onClick = {getStudentResults}/>
                        {
                            resultList.map((student, i)=>(
                                    <div key = {i} className = 'brad'>
                                        <p className = 'text'> S/N: {i}</p>
                                        <p className = 'details'>Student details</p>
                                        <p className = 'text'>{`${student.lastname} ${student.firstname} ${student.reg_number}`}</p>
                                        <p className = 'details'>Score</p>
                                        <p className = 'text'>{student.score}</p>
                                    </div>
                            ))
                        }
                    </div>
                )
            case "allStudents":
                return(
                    <div className = 'all-students box'>
                        <h3>All Student</h3>
                        <input className = 'button' type = 'submit' value = 'View' onClick = {getAllStudents}/>
                        {
                            studentList.map((student, i)=>(
                                    <div key = {i} className = 'brad'>
                                        <p className = 'text'> S/N: {i}</p>
                                        <p className = 'details'>Registration number</p>
                                        <p className = 'text'>{student.reg_number}</p>
                                        <p className = 'details'>Last name</p>
                                        <p className = 'text'>{student.lastname}</p>
                                        <p className = 'details'>First name</p>
                                        <p className = 'text'>{student.firstname}</p>
                                    </div>
                            ))
                        }
                    </div>
                )
            case "changePassword":
                return(
                    <div className = 'change-password box'>
                        <h3>Change password</h3>
                        <form onSubmit = {handleSubmit(changePassword)}>
                            <input 
                                className = 'text' 
                                type = 'text' 
                                name = 'reg_number' 
                                placeholder = 'Registration number' 
                                ref = {register({
                                    required: "required"
                                })}
                            /><br/><br/>
                            <input 
                                className = 'text' 
                                type = 'text' 
                                name = 'password'  
                                placeholder = 'Password' 
                                ref = {register({
                                    required: "required"
                                })}
                            /><br/><br/>
                            <input className = 'button' type = 'submit' value = 'Change'/>
                        </form>
                    </div>
                )
            case "deleteStudent":
                return(
                    <div className = 'delete-student box'>
                        <h3>Delete Student</h3>
                        <form onSubmit = {handleSubmit(deleteStudent)}>
                            <input 
                                className = 'text' 
                                name = 'reg_number'
                                placeholder = 'Registration number'
                                ref = {register({
                                    required: "required"
                                })}
                            /><br/><br/>
                            <input className = 'button' type = 'submit' value = 'Delete'/>
                        </form>
                    </div>
                )
            case "uploadQuestion":
                return(
                    <div className = 'upload-question box'>
                        <h3>Upload question</h3>
                        <form onSubmit = {handleSubmit(uploadQuestion)}>
                            <textarea 
                                className = 'textarea' 
                                name = 'text' 
                                placeholder = 'Enter question'
                                ref = {register({
                                    required: "required"
                                })}
                            /><br/><br/>
                            <input 
                                className = 'text' 
                                type = 'text' 
                                name = 'optiona'  
                                placeholder = 'Option A'
                                ref = {register({
                                    required: "required"
                                })}
                            /><br/><br/>
                            <input 
                                className = 'text' 
                                type = 'text' 
                                name = 'optionb'  
                                placeholder = 'Option B'
                                ref = {register({
                                    required: "required"
                                })}
                            /><br/><br/>
                            <input 
                                className = 'text' 
                                type = 'text' 
                                name = 'optionc'  
                                placeholder = 'Option C'
                                ref = {register({
                                    required: "required"
                                })}
                            /><br/><br/>
                            <input 
                                className = 'text' 
                                type = 'text' 
                                name = 'optiond'  
                                placeholder = 'Option D'
                                ref = {register({
                                    required: "required"
                                })}
                            /><br/><br/>
                            <input 
                                className = 'text' 
                                type = 'text' 
                                name = 'diagram'  
                                placeholder = 'Diagram Url'
                                ref = {register()}
                            /><br/><br/>
                            <p>Answer</p>
                            <div className = 'radio'><input type = 'radio' name = 'answer' value = 'A' ref = {register()}/>A</div>
                            <div className = 'radio'><input type = 'radio' name = 'answer' value = 'B' ref = {register()}/>B</div>
                            <div className = 'radio'><input type = 'radio' name = 'answer' value = 'C' ref = {register()}/>C</div>
                            <div className = 'radio'><input type = 'radio' name = 'answer' value = 'D' ref = {register()}/>D</div>
                            <p>Course</p>
                            <select name = 'course' ref = {register()}>
                                <option value = "MAT">Maths</option>
                                <option value = "ENG">English</option>
                                <option value = "GEN">General</option>
                            </select>
                            <input className = 'button' type = 'submit' value = 'Upload'/>
                        </form>
                    </div>
                )
            default:
                return(
                    <p>No page to display</p>
                )
        }
    }

    return(
        <div className = 'admin-container' >
            <div className = 'sub-container'>
                <UtmeAdminHeading error = {adminError} onRouteChange = {logOut} changePage = {onAdminPageChange}/>
                {displayCurrentView()}
            </div>
        </div>
    )
}

export default UtmeAdminDashboard;