import React from 'react';
import './examLink.css';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PdfDocument from '../PdfDocument/pdfDocument';

class ExamLink extends React.Component{
	constructor(props){
		super(props)
		this.state = {
			studentDetails: props.student
		}
	}

	componentDidMount(){
		const studentDetails = this.props.student
		fetch('https://ws-mock-api.herokuapp.com/result')
		.then(res=>res.json())
		.then(data=>{
			if(data.length && data !== 'there was an error'){
				const studentResult = data.find((result)=>{
					return result.matricno === this.props.student.matricno
				})

				studentDetails.result = studentResult.score
				studentDetails.gpa = studentResult.gpa

				this.setState({studentDetails})
					
			}
		})
		.catch(err=>{
			console.log('unable to access server')
		})
	}

	startExam = (course)=>{
		if(this.props.student.written.includes(course.code)){
			alert('No more attempt for this course')
		}else{
			this.props.onAPIrequest(true)
			fetch('https://ws-mock-api.herokuapp.com/question', {
				method: 'post',
				headers: {'content-Type': 'application/json'},
				body: JSON.stringify({
					code: course.code
				})
			})
			.then(res=> res.json())
			.then(data=>{
				if(data !== 'there was an error'){
					this.props.loadQuestion(data)
					this.props.onAPIrequest(false)
					this.props.onRouteChange("Exam");
					window.scrollTo({top: 0, behaviour: 'smooth'});
				}
			})
			.catch(err=>{
				console.log('unable to access server')
				this.props.onAPIrequest(false)
			})
			
		}
		
	}
	
	render(){
		if(this.props.examCourse !== 'None' && this.props.examCourse !== 'Result'){
			const course = this.props.student.courses.find((courseObj)=>{
				return courseObj.code === this.props.examCourse
			})
			if(course){
				return(
					<div className = 'examLink'>
						<div className = 'subject-details'>
							<p>course title: {course.title}</p>
							<p>course code: {course.code}</p>
							<p>course unit: {course.unit}</p>
							<p>Total Questions: {course.totalquestions}</p>
							<p>Exam duration: {course.duration} minutes</p>
							<p>Note: Your timer starts immediately you start the exam. Get ready before starting. GOODLUCK!</p>
						</div>
						<div className = 'link' onClick = {()=>{this.startExam(course)}}>
							<p>CLICK HERE TO START EXAM</p>
						</div>
					</div>
				)
			}else{
				return(
					<div className = 'examLink'><p>YOU DO NOT HAVE ANY CURRENT EXAM</p></div>
				)
			}
		}else if(this.props.examCourse === 'Result'){
			if(this.state.studentDetails.gpa !== null){
				return(
					<div className = 'examLink'>
					{
						this.state.studentDetails.result &&
						<PDFDownloadLink
							document = {<PdfDocument student = {this.state.studentDetails}/>}
							fileName = 'result.pdf'
							style = {{
								padding: '5px 20px',
								textDecoration: 'none',
								backgroundColor: '#27ADB0',
								color: '#ffffff',
								outline: 'none',
								border: 'none',
								borderRadius: '15px',
								marginBottom: '10px',
								fontSize: '17px'
							}}
						>
							Download Result
						</PDFDownloadLink>
					}
					</div>
				)
			}else{
				return(
					<div className = 'examLink'><p>YOU DO NOT HAVE ANY CURRENT EXAM</p></div>
				)
			}

		}else{
			return(
				<div className = 'examLink'><p>YOU DO NOT HAVE ANY CURRENT EXAM</p></div>
			)
		}
	}	
}

export default ExamLink;