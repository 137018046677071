import React from 'react';

const UploadQuestion = (props)=>{
	return(
		<div className = 'upload-question box'>
			<h3>Upload question</h3>
			<div>
				<textarea className = 'textarea' name = 'text' placeholder = 'Enter question'/><br/><br/>
				<input className = 'text' type = 'text' name = 'optiona'  placeholder = 'Option A'/><br/><br/>
				<input className = 'text' type = 'text' name = 'optionb'  placeholder = 'Option B'/><br/><br/>
				<input className = 'text' type = 'text' name = 'optionc'  placeholder = 'Option C'/><br/><br/>
				<input className = 'text' type = 'text' name = 'optiond'  placeholder = 'Option D'/><br/><br/>
				<input className = 'text' type = 'text' name = 'diagram'  placeholder = 'Diagram Url'/><br/><br/>
				<p>Answer</p>
				<form>
					<div className = 'radio'><input type = 'radio' name = 'answer' value = 'A'/>A</div>
					<div className = 'radio'><input type = 'radio' name = 'answer' value = 'B'/>B</div>
					<div className = 'radio'><input type = 'radio' name = 'answer' value = 'C'/>C</div>
					<div className = 'radio'><input type = 'radio' name = 'answer' value = 'D'/>D</div>
				</form>
				<p>Course</p>
				<select name = 'course'>
				{
					props.courseList.map((course, i)=>{
						return	<option key = {i} value = {course}>{course}</option>
					})
				}
				</select>
				<input className = 'button' type = 'submit' value = 'Upload' onClick = {props.uploadQuestion}/>
			</div>
		</div>
	)
}

export default UploadQuestion;