import React from 'react';

const DeleteCourse = (props)=>{
	return(
		<div className = 'delete-course box'>
			<h3>Delete Course</h3>
			<input className = 'text' type = 'text' name = 'code' placeholder = 'Course code'/><br/><br/>
			<input className = 'button' type = 'submit' value = 'Delete' onClick = {props.deleteCourse}/>
		</div>
	)
}

export default DeleteCourse;