import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';



const UtmeAdminLogin = ({ logAdminIn })=>{
	const [loginError, setLoginError] = useState()
	
	const history = useHistory();
	
    const{
        register,
        handleSubmit
    } = useForm();

    const focus = (event)=>{
		const parent = event.target.parentNode;
		parent.classList.add('focus');
	}

	const blur = (event)=>{
		let parent = event.target.parentNode;
		if(event.target.value === ""){
			parent.classList.remove('focus');
		}
    }
    
    const onAdminLogin = (formData)=>{
		window.scrollTo({top: 0, behaviour: 'smooth'})
		setLoginError("")
		fetch(`${process.env.REACT_APP_API_URL}/auth/adminLogin`, {
			method: 'post',
			headers: {'content-Type': 'application/json'},
			body: JSON.stringify(formData)
		})
		.then(res=> res.json())
		.then(data=> {
			if(data.admin_id){
				logAdminIn()
				history.push('/postutme/admin/dashboard')
			}else{
				setLoginError('Invalid details')
			}
		})
		.catch(err=> console.log('unable to access server'))
    }

    return(
        <div>
			<div className = 'curved-header'>
				<div>
					<p className = 'hTxt'><b>WALTERSAM SERVICES</b></p>
					<p className = 'sTxt'>UNILAG POST UTME MOCK EXAMINATION</p>
				</div>
			</div>
			<div className = 'login'>
				<div>
					<div className = 'login-header'>
                        <h3>ADMIN</h3>
					</div>
					<div className = 'login-container'>
						<form onSubmit = {handleSubmit(onAdminLogin)}>
							<p className = 'error'>{loginError}</p>
							<div className = 'input-div'>
								<input 
									onFocus = {focus}
									onBlur = {blur}
									className = 'input' 
									type = 'text'
									name = 'username'
									placeholder = 'username'
									ref = {register({
                                        required: "required"
                                    })}
								/>
							</div>
							<div className = 'input-div'>
								<input 
									onFocus = {focus}
									onBlur = {blur}
									className = 'input' 
									type = 'password'
									name = 'password'
									placeholder = 'password'
									ref = {register({
                                        required: "required"
                                    })}
								/>
							</div>
							<div>
								<input className = 'btn' type='submit' value ='Login'/>
							</div>
                        </form>
						
					</div>
				</div>
			</div>
		</div>
    )
}

export default UtmeAdminLogin;