import React from 'react';
import './questionTag.css';


const QuestionTag = (props)=>{
	return(
		<div className = 'questionTag'>
			{
				props.questionList.map((page, i)=>{
					return(
						page.map((question, j)=>{
							const number = j+1 > 9? `${i + 1}0`: `${i}${j+1}`;
							if(question.answered){
								return <p key = {j} className = 'tag answered'>{number}</p>
							}else{
								return <p key = {j} className = 'tag'>{number}</p>
							}

						})
					)

				})
			}
		</div>
	)
}

export default QuestionTag;