import React from 'react';

const DeleteStudent = (props)=>{
	return(
		<div className = 'delete-student box'>
			<h3>Delete Student</h3>
			<input className = 'text' type = 'text' name = 'matric' placeholder = 'Matric number'/><br/><br/>
			<input className = 'button' type = 'submit' value = 'Delete' onClick = {props.deleteStudent}/>
		</div>
	)
}

export default DeleteStudent;