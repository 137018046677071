import React from 'react';


const StudentDetails = (props)=>{
	return(
		<div className = 'student-details box'>
			<h3>Student Details</h3>
			<input className = 'text' type = 'text' name = 'matric' placeholder = 'Matric number'/><br/><br/>
			<input className = 'button' type = 'submit' value = 'Get details' onClick = {props.studentDetails}/>
			{props.student.matricno
				?
				<div>
					<p className = 'details'>Matric number</p>
					<p className = 'text'>{props.student.matricno}</p>
					<p className = 'details'>Lastname</p>
					<p className = 'text'>{props.student.lastname}</p>
					<p className = 'details'>Firstname</p>
					<p className = 'text'>{props.student.firstname}</p>
					<p className = 'details'>Faculty</p>
					<p className = 'text'>{props.student.faculty}</p>
					<p className = 'details'>Department</p>
					<p className = 'text'>{props.student.department}</p>
					<p className = 'details'>Registered Courses</p>
					{
						props.student.courses.map((course, i)=>{
							return(
								<div key = {i} className = "tile">
									<p>{course}</p>
								</div>
							)
						})
					}
					<p className = 'details'>Written Courses</p>
					{
						props.student.written.map((course, i)=>{
							return(
								<div key = {i} className = "tile">
									<p>{course}</p>
								</div>
							)
						})
					}
					<p className = 'details'>result</p>
					{
						props.student.result.map((result, i)=>{
							return(
								<div key = {i} className = "tile">
									<p>{result.code}</p>
									<p>{result.score}</p>
									<p>{result.grade}</p>
								</div>
							)
						})
					}
					<p>{props.student.gpa}</p><br/>
				</div>
				:
				<div></div>
			}
		</div>
	)
}

export default StudentDetails;