import React from 'react';
import { useForm } from 'react-hook-form';


const Verify = ({onSubmitVerify, verifyError})=>{

    const {
        register,
        handleSubmit,
        errors
    } = useForm()
    
    return(
        <div>
            <h3 className = 'register-header'>Register</h3>
            <div className = 'container'>
                <p className = 'error'>{verifyError}</p>
                <form onSubmit = {handleSubmit(onSubmitVerify)}>
                    <input 
                        className = 'text' 
                        name = 'studentToken' 
                        placeholder = 'Enter Registration Code' 
                        ref = {register({
                            required: "Please provide registration code"
                        })}
                    /><br/>
                    {errors.reg_code && <h6 className = 'vError'>{errors.reg_code.message}</h6>}
                    <br/>
                    <input className = 'button' type = 'submit' value = 'verify'/><br/>
                </form>
            </div>
        </div>
    )
}

export default Verify;