import React from 'react';


const AllStudents = (props)=>{
	return(
		<div className = 'all-students box'>
			<h3>All Student</h3>
			<select name = 'course'>
				<option value = 'None'>None</option>
			{
				props.courseList.map((course, i)=>{
					return	<option key = {i} value = {course}>{course}</option>
				})
			}
			</select>
			<input className = 'button' type = 'submit' value = 'View' onClick = {props.allStudents}/>
			{
				props.studentList.map((student, i)=>{
					return(
						<div key = {i} className = 'brad'>
							<p className = 'text'> S/N: {i}</p>
							<p className = 'details'>Matric number</p>
							<p className = 'text'>{student.matricno}</p>
							<p className = 'details'>Department</p>
							<p className = 'text'>{student.department}</p>
							<p className = 'details'>Registered Courses</p>
							{
								student.courses.map((course, i)=>{
									return(
										<div key = {i} className = "tile">
											<p>{course}</p>
										</div>
									)
								})
							}
						</div>
					)
				})
		
			}
		</div>
	)
}

export default AllStudents;