import React, { Component} from 'react';
import Body from '../Body/body';
import Header from '../Header/header';
import Summary from '../Summary/summary';


class Exam extends Component{
	constructor(props){
		super(props);
		this.state = {
			examState : 'on'
		}
	}

	leaveExam = (code, unit, score, totalquestions, student)=>{
		this.props.onAPIrequest(true)
		const per = Math.round((score/totalquestions)*100)
		let grade = '';
		let gradePoint = 0;

		if(per >= 70){
			grade = 'A'
			gradePoint = 5
		}else if(per >= 60){
			grade = 'B'
			gradePoint = 4
		}else if(per >= 50){
			grade = 'C'
			gradePoint = 3
		}else if(per >= 45){
			grade = 'D'
			gradePoint = 2
		}else if(per >= 40){
			grade = 'E'
			gradePoint = 1
		}else{
			grade = 'F'
			gradePoint = 0
		}

		fetch('https://ws-mock-api.herokuapp.com/written', {
			method: 'put',
			headers: {'content-Type': 'application/json'},
			body: JSON.stringify({
				matricno: student.matricno,
				coursecode: code
			})
		})
		.then(
			fetch('https://ws-mock-api.herokuapp.com/updateResult', {
				method: 'put',
				headers: {'content-Type': 'application/json'},
				body: JSON.stringify({
					matricno: student.matricno,
					result: {
						code, 
						unit, 
						score: per,
						grade,
						gradePoint
					}
				})
			})
			.then(res=> res.json())
			.then(data=>{
				this.props.onAPIrequest(false)
				this.setState({examState: 'off'})
			})
			.catch(err=>{
				console.log('unable to access server')
				this.props.onAPIrequest(false)
			})
		)
		.catch(err=>{
			console.log('unable to access server')
			this.props.onAPIrequest(false)
		})
		
	}

	
	render(){
		if(this.state.examState === 'on'){
			return(
				<div>
					<Header logout = {false} examCourseObj = {this.props.examCourseObj}/>
					<Body 
						student = {this.props.student} 
						examCourseObj = {this.props.examCourseObj} 
						questionArray = {this.props.questionArray} 
						leaveExam = {this.leaveExam}
					/>
				</div>
			)
		}else{
			return(
				<div>
					<Header logout = {true} onRouteChange = {this.props.onRouteChange}/>
					<Summary 
						student = {this.props.student} 
						examCourseObj = {this.props.examCourseObj} 
						onRouteChange = {this.props.onRouteChange}
						updateWritten = {this.props.updateWritten}
					/>
				</div>
			)
		}
	}
}
export default Exam;