import React from 'react';

const ChangePassword = (props)=>{
	return(
		<div className = 'change-password box'>
			<h3>Change password</h3>
			<div>
				<input className = 'text' type = 'text' name = 'matricno' placeholder = 'Matric number' required/><br/><br/>
				<input className = 'text' type = 'text' name = 'password'  placeholder = 'Password' required/><br/><br/>
				<input className = 'button' type = 'submit' value = 'Change' onClick = {props.changePassword}/>
			</div>
		</div>
	)
}

export default ChangePassword;