import React from 'react';
import './summary.css';

const Summary = (props)=>{
	return(
		<div>
			<div className = 'info'>
				<div className = 'student-details'>
					<p>Name: {props.student.lastname}, {props.student.firstname}</p>
					<p>Matric Number: {props.student.matricno}</p>
					<p>Department: {props.student.department}</p>
				</div>
				<div className = 'subject-details'>
					<p>Course Title: {props.examCourseObj.title}</p>
					<p>Course Code: {props.examCourseObj.code}</p>
					<p>Course Unit: {props.examCourseObj.unit}</p>
					<p>Total Questions: {props.examCourseObj.totalquestions}</p>
				</div>
			</div>
			<div className = 'studentPageLink'>
				<p onClick = {()=>{
					props.updateWritten(props.examCourseObj.code)
					props.onRouteChange('StudentPage')
				}}>Go to profile</p>
			</div>
			<div className = 'Submitted'>
				<p>SUBMITTED! Your Score Has Been Recorded</p>
			</div>
			<div className = 'footer'>
				<p>mockexam@waltersamservices 2021</p>
			</div>
		</div>
	)
}

export default Summary;