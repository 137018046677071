import React from 'react';
import './adminProfile.css';
import AdminHeading from '../AdminHeading/adminHeading';
import GenerateToken from '../GenerateToken/generateToken';
import StudentDetails from '../StudentDetails/studentDetails';
import Register from '../Register/register';
import AllStudents from '../AllStudents/allStudents';
import ChangePassword from '../ChangePassword/changePassword';
import DeleteStudent from '../DeleteStudent/deleteStudent';
import UploadCourse from '../UploadCourse/uploadCourse';
import DeleteCourse from '../DeleteCourse/deleteCourse';
import UploadQuestion from '../UploadQuestion/uploadQuestion';
import ExamCourse from '../ExamCourse/examCourse';
import Result from '../Result/result';


class AdminProfile extends React.Component{
	constructor(props){
		super(props)
		this.state = {
			courseList: [],
			page: 'register',
			student: '',
			studentList: [],
			studentResult: [],
			token: '',
			error: ''
		}
	}
	static getDerivedStateFromProps(props, state){
		return {courseList: props.courseList}
	}

	changePage = (page)=>{
		this.setState({page})
	}
	generateToken = (event)=>{
		this.setState({error: ''})

		fetch('https://ws-mock-api.herokuapp.com/generateToken')
		.then(res=> res.json())
		.then(data=> {
			this.setState({token: data})
			
		})
		.catch(err=> console.log('unable to access server'))
	}
	register = (event)=>{
		this.setState({error: ''})
		const registerForm = event.target
		
		const courses = []
		const matricno = registerForm.parentNode.childNodes[0].value
		const password = registerForm.parentNode.childNodes[3].value
		const lastname = registerForm.parentNode.childNodes[6].value
		const firstname = registerForm.parentNode.childNodes[9].value
		const faculty = registerForm.parentNode.childNodes[12].value
		const department = registerForm.parentNode.childNodes[15].value
		
		event.target.parentNode.childNodes[19].childNodes.forEach((course)=>{
			if(course.firstChild.checked){
				courses.push(course.firstChild.name)
			}
		})
		
		fetch('https://ws-mock-api.herokuapp.com/register', {
			method: 'post',
			headers: {'content-Type': 'application/json'},
			body: JSON.stringify({
				matricno, password, lastname, firstname, faculty, department, courses
			})
		})
		.then(res=> res.json())
		.then(data=> {
			this.setState({error: data})
			registerForm.parentNode.childNodes[0].value = ''
			registerForm.parentNode.childNodes[3].value = ''
			registerForm.parentNode.childNodes[6].value = ''
			registerForm.parentNode.childNodes[9].value = ''
			registerForm.parentNode.childNodes[12].value = ''
			registerForm.parentNode.childNodes[15].value = ''
		})
		.catch(err=> console.log('unable to access server'))
	}

	studentDetails = (event)=>{
		this.setState({error: ''})
		this.setState({student: ''})
		const input = event.target

		const matricno = input.parentNode.childNodes[1].value
		fetch('https://ws-mock-api.herokuapp.com/studentDetails', {
			method: 'post',
			headers: {'content-Type': 'application/json'},
			body: JSON.stringify({
				matricno
			})
		})
		.then(res=> res.json())
		.then(data=>{
			if(data.matricno){
				this.setState({student: data})
				input.parentNode.childNodes[1].value = ''
			}else{
				this.setState({error: data})
				input.parentNode.childNodes[1].value = ''
			}
		})
		.catch(err=> console.log('unable to access server'))
	}

	updateGPA = (event)=>{
		fetch('https://ws-mock-api.herokuapp.com/updateGPA', {
			method: 'put',
			headers: {'content-Type': 'application/json'},
			body: JSON.stringify({
				message:'updateGPA'
			})
		})
		.then(res=> res.json())
		.then(data=> this.setState({error: data}))
		.catch(err=> console.log('unable to access server'))
	}

	allStudents = (event)=>{
		this.setState({studentList: []})
		this.setState({error: ''})
		const code = event.target.parentNode.childNodes[1].value
		fetch('https://ws-mock-api.herokuapp.com/students')
		.then(res=> res.json())
		.then(data=>{
			if(data !== 'there was an error'){
				if(code === 'None'){
					this.setState({studentList: data})
				}else{
					const list = data.filter((student)=>{
						return student.courses.includes(code)
					})
					this.setState({studentList: list})
				}
			}else{
				this.setState({error: data})
			}
			
		})
		.catch(err=> console.log('unable to access server'))
	}

	changePassword = (event)=>{
		this.setState({error: ''})
		const form = event.target

		const matricno = form.parentNode.childNodes[0].value
		const password = form.parentNode.childNodes[3].value

		fetch('https://ws-mock-api.herokuapp.com/changePassword', {
			method: 'put',
			headers: {'content-Type': 'application/json'},
			body: JSON.stringify({
				matricno, password
			})
		})
		.then(res=> res.json())
		.then(data=>{
			this.setState({error: data})
			form.parentNode.childNodes[0].value = ''
			form.parentNode.childNodes[3].value = ''
		})
		.catch(res=> console.log('unable to access server'))
	}

	deleteStudent = (event)=>{
		this.setState({error: ''})
		const input = event.target

		const matricno = input.parentNode.childNodes[1].value
		fetch('https://ws-mock-api.herokuapp.com/deleteStudent', {
			method: 'delete',
			headers: {'content-Type': 'application/json'},
			body: JSON.stringify({
				matricno
			})
		})
		.then(res=>res.json())
		.then(data=>{
			this.setState({error: data})
			input.parentNode.childNodes[1].value = ''
		})
		.catch(err=> console.log('unable to access server'))
	}

	uploadCourse = (event)=>{
		this.setState({error: ''})
		const courseForm = event.target
		
		const code = courseForm.parentNode.childNodes[0].value
		const title = courseForm.parentNode.childNodes[3].value
		const unit = courseForm.parentNode.childNodes[6].value
		const totalquestions = courseForm.parentNode.childNodes[9].value
		const duration = courseForm.parentNode.childNodes[12].value

		fetch('https://ws-mock-api.herokuapp.com/uploadCourse', {
			method: 'post',
			headers: {'content-Type': 'application/json'},
			body: JSON.stringify({
				code, title, unit, totalquestions, duration
			})
		})
		.then(res=> res.json())
		.then(data=> {
			this.setState({error: data})
			courseForm.parentNode.childNodes[0].value = ''
			courseForm.parentNode.childNodes[3].value = ''
			courseForm.parentNode.childNodes[6].value = ''
			courseForm.parentNode.childNodes[9].value = ''
			courseForm.parentNode.childNodes[12].value = ''
		})
		.catch(err=> console.log('unable to access server'))
	}

	deleteCourse = (event)=>{
		this.setState({error: ''})
		const input = event.target

		const code = input.parentNode.childNodes[1].value

		fetch('https://ws-mock-api.herokuapp.com/deleteCourse', {
			method: 'delete',
			headers: {'content-Type': 'application/json'},
			body: JSON.stringify({
				code
			})
		})
		.then(res=> res.json())
		.then(data=>{
			this.setState({error: data})
			input.parentNode.childNodes[1].value = ''
		})
		.catch(err=> console.log('unable to access server'))
	}

	uploadQuestion = (event)=>{
		this.setState({error: ''})
		const questionForm = event.target

		const text = questionForm.parentNode.childNodes[0].value
		const optiona = questionForm.parentNode.childNodes[3].value
		const optionb = questionForm.parentNode.childNodes[6].value
		const optionc = questionForm.parentNode.childNodes[9].value
		const optiond = questionForm.parentNode.childNodes[12].value
		const diagram = questionForm.parentNode.childNodes[15].value
		const answer = questionForm.parentNode.childNodes[19].elements['answer'].value
		const coursecode = event.target.parentNode.childNodes[21].value

		fetch('https://ws-mock-api.herokuapp.com/uploadQuestion', {
			method: 'post',
			headers: {'content-Type': 'application/json'},
			body: JSON.stringify({
				text, optiona, optionb, optionc, optiond, diagram, answer, coursecode
			})
		})
		.then(res=> res.json())
		.then(data=>{
			this.setState({error: data})
			questionForm.parentNode.childNodes[0].value = ''
			questionForm.parentNode.childNodes[3].value = ''
			questionForm.parentNode.childNodes[6].value = ''
			questionForm.parentNode.childNodes[9].value = ''
			questionForm.parentNode.childNodes[12].value = ''
			questionForm.parentNode.childNodes[16].elements['answer'].value = ''
			questionForm.parentNode.childNodes[18].value = ''
		})
		.catch(err=> console.log('unable to access server'))
	}

	setExamCourse = (event)=>{
		this.setState({error: ''})
		const course = event.target.parentNode.childNodes[1].value
		fetch('https://ws-mock-api.herokuapp.com/examCourse', {
			method: 'put',
			headers: {'content-Type': 'application/json'},
			body: JSON.stringify({
				code: course
			})
		})
		.then(res=> res.json())
		.then(data=> {
			this.setState({error: data})
		})
		.catch(err=> console.log('unable to access server'))
	}

	result = (event)=>{
		this.setState({error: ''})
		this.setState({studentResult: []})
		const course = event.target.parentNode.childNodes[1].value
		fetch('https://ws-mock-api.herokuapp.com/result')
		.then(res=>res.json())
		.then(data=>{
			if(data.length && data !== 'there was an error'){
				const studentResult = []
				data.forEach((student)=>{
					const result = student.score.find((result)=>{
						return course === result.code
					})
					if(result){
						studentResult.push({
							matricno: student.matricno, 
							score: result.score,
							grade: result.grade
						})
					}
				})

				this.setState({studentResult})
			}else{
				this.setState({error: 'No result found'})
			}
			
		})
		.catch(err=> console.log('unable to access server'))
	}

	render(){
		if(this.state.page === 'generateToken'){
			return(
				<div className = 'admin-container' >
					<div className = 'sub-container'>
						<AdminHeading error = {this.state.error} onRouteChange = {this.props.onRouteChange} changePage = {this.changePage} updateGPA = {this.updateGPA}/>
						<GenerateToken generateToken = {this.generateToken} token = {this.state.token}/>
					</div>
				</div>
			)
		}else if(this.state.page === 'register'){
			return(
				<div className = 'admin-container' >
					<div className = 'sub-container'>
						<AdminHeading error = {this.state.error} onRouteChange = {this.props.onRouteChange} changePage = {this.changePage} updateGPA = {this.updateGPA}/>
						<Register register = {this.register} courseList = {this.state.courseList}/>
					</div>
				</div>
			)
		}else if(this.state.page === 'studentDetails'){
			return(
				<div className = 'admin-container' >
					<div className = 'sub-container'>
						<AdminHeading error = {this.state.error} onRouteChange = {this.props.onRouteChange} changePage = {this.changePage} updateGPA = {this.updateGPA}/>
						<StudentDetails studentDetails = {this.studentDetails} student = {this.state.student}/>
					</div>
				</div>
			)
		}else if(this.state.page === 'allStudents'){
			return(
				<div className = 'admin-container' >
					<div className = 'sub-container'>
						<AdminHeading error = {this.state.error} onRouteChange = {this.props.onRouteChange} changePage = {this.changePage} updateGPA = {this.updateGPA}/>
						<AllStudents allStudents = {this.allStudents} courseList = {this.state.courseList} studentList = {this.state.studentList}/>
					</div>
				</div>
			)
		}else if(this.state.page === 'changePassword'){
			return(
				<div className = 'admin-container' >
					<div className = 'sub-container'>
						<AdminHeading error = {this.state.error} onRouteChange = {this.props.onRouteChange} changePage = {this.changePage} updateGPA = {this.updateGPA}/>
						<ChangePassword changePassword = {this.changePassword}/>
					</div>
				</div>
			)
		}else if(this.state.page === 'deleteStudent'){
			return(
				<div className = 'admin-container' >
					<div className = 'sub-container'>
						<AdminHeading error = {this.state.error} onRouteChange = {this.props.onRouteChange} changePage = {this.changePage} updateGPA = {this.updateGPA}/>
						<DeleteStudent deleteStudent = {this.deleteStudent}/>
					</div>
				</div>	
			)
		}else if(this.state.page === 'uploadCourse'){
			return(
				<div className = 'admin-container' >
					<div className = 'sub-container'>
						<AdminHeading error = {this.state.error} onRouteChange = {this.props.onRouteChange} changePage = {this.changePage} updateGPA = {this.updateGPA}/>
						<UploadCourse uploadCourse = {this.uploadCourse}/>
					</div>
				</div>	
			)
		}else if(this.state.page === 'deleteCourse'){
			return(
				<div className = 'admin-container' >
					<div className = 'sub-container'>
						<AdminHeading error = {this.state.error} onRouteChange = {this.props.onRouteChange} changePage = {this.changePage} updateGPA = {this.updateGPA}/>
						<DeleteCourse deleteCourse = {this.deleteCourse}/>
					</div>
				</div>	
			)
		}else if(this.state.page === 'uploadQuestion'){
			return(
				<div className = 'admin-container' >
					<div className = 'sub-container'>
						<AdminHeading error = {this.state.error} onRouteChange = {this.props.onRouteChange} changePage = {this.changePage} updateGPA = {this.updateGPA}/>
						<UploadQuestion uploadQuestion = {this.uploadQuestion} courseList = {this.state.courseList}/>
					</div>
				</div>	
			)
		}else if(this.state.page === 'examCourse'){
			return (
				<div className = 'admin-container' >
					<div className = 'sub-container'>
						<AdminHeading error = {this.state.error} onRouteChange = {this.props.onRouteChange} changePage = {this.changePage} updateGPA = {this.updateGPA}/>
						<ExamCourse setExamCourse = {this.setExamCourse} courseList = {this.state.courseList}/>
					</div>
				</div>	
			)
		}else if(this.state.page === 'result'){
			return(
				<div className = 'admin-container' >
					<div className = 'sub-container'>
						<AdminHeading error = {this.state.error} onRouteChange = {this.props.onRouteChange} changePage = {this.changePage} updateGPA = {this.updateGPA}/>
						<Result result = {this.result} courseList = {this.state.courseList} studentResult = {this.state.studentResult}/>
					</div>
				</div>	
			)
		}
	}
}

export default AdminProfile;