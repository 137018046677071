import React, { useState } from 'react';
import Diagram from '../../Components/Portal/Diagram/diagram';
import Question from '../../Components/Portal/Question/question';
import QuestionTag from '../../Components/Portal/QuestionTag/questionTag';
import Timer from '../../Components/Portal/Timer/timer';
import PageNavigation from '../../Components/Portal/PageNavigation/pageNavigation';
import Submit from '../../Components/Portal/Submit/submit';
import UtmeLoading from '../UtmeLoading/UtmeLoading';
import logo from '../../images/wsLogo.jpg';
import './UtmeExam.css';


const UtmeExam = ({ student, dashboardChange, propsQuestionList })=>{
    
    const [page, setPage] = useState("Exam")
    const [examPage, setExamPage] = useState(0);
    const [questionList, setQuestionList] = useState(propsQuestionList);
    const [image, setImage] = useState({
        url: "",
        show: false
    });
    const [hits, setHits] = useState(0);
    const [time, setTime] = useState({
        enabled: true,
        timeSpent: ""
    })
    const [questionsAnswered, setQuestionsAnswered] = useState(0)
    const [finishAlert, setFinishAlert] = useState(false)
    const [loading, setLoading] = useState(false)

    const onShowImage = (imageUrl)=>{
        setImage({
            url: imageUrl,
            show: true
        })
    }

    const onCloseImage = ()=>{
        setImage({
            url: "",
            show: false
        })
    }
    
    const onSelectOption = (event)=>{
        const form = event.target.parentNode.parentNode.parentNode;
        const questionId = event.target.id
		const newQuestionList = questionList;
		newQuestionList[examPage][questionId].sAnswer = form.elements[event.target.name].value;
		newQuestionList[examPage][questionId].answered = true
        setQuestionList(newQuestionList);
        setHits(hits + 1)
    }

    const onNextPage = ()=>{
        setExamPage(examPage + 1)
        window.scrollTo({top: 0, behaviour: 'smooth'});
    }

    const onPreviousPage = ()=>{
        setExamPage(examPage - 1)
        window.scrollTo({top: 0, behaviour: 'smooth'});
    }

    const finishAttempt = (timeLeft)=>{
        setLoading(true)
        const secondsSpent = 1800 - timeLeft
        const minutesSpent = Math.floor(secondsSpent / 60)
        const secondsLeft = Math.floor(secondsSpent % 60)
        
        const timeString = `${minutesSpent}minutes  ${secondsLeft}seconds`

        let done = false
        let noOfAnswered = 0
        let score = 0
		questionList.forEach((page, i)=>{
			page.forEach((question, j)=>{
                if(question.answered){
                    noOfAnswered++
                }
				if(question.answer === question.sAnswer){
                    score++
                }
                if(i === questionList.length-1 && j === page.length-1){
                    done = true;
                }
			})
        })

        if(done){
            setQuestionsAnswered(noOfAnswered)
            setTime({
                enabled: false,
                timeSpent: timeString
            })
            fetch(`${process.env.REACT_APP_API_URL}/student/uploadResult`, {
                method: 'post',
                headers: {'content-Type': 'application/json'},
                body: JSON.stringify({
                    student, 
                    score, 
                    noOfAnswered, 
                    timeTaken: timeString
                })
            })
            .then(res=> res.json())
            .then(data =>{
                if(data === 'success'){
                    setLoading(false)
                    setPage("Summary")
                }else{
                    alert("An error occured")
                    setLoading(false)
                    dashboardChange()
                }
            })
        }
    }
    
    const onSelectFinishOption = (event)=>{
        setFinishAlert(false)
        if(event.target.className === 'yes'){
            setTime({
                ...time,
                enabled: false
            })
        }
    }

    return(
        <div>
            {
                loading && <UtmeLoading/>
            }
            <div className = "Utme_header">
                <div className = "Utme_header-logo-wrapper">
                    <img className = "Utme_header-logo"src={logo} alt ='logo'/>
                    <p className = "Utme_header-logo-text">Waltersam Services</p>
                </div>
            </div>
        { page === "Exam"?
            <div className = 'body-container'>
            {
                image.show &&
                <Diagram url = {image.url} onCloseImage = {onCloseImage} />
            }
				<div className = 'questions'>
                {
                    questionList.map((page, i)=>(
                        <div key = {i} className = {`${i !== examPage? "donotdisplay": ""}`}>
                        {
                            page.map((question, j)=>{
                                return(
                                    <Question 
                                        key = {i.toString() + j} 
                                        question = {question} 
                                        page = {i} 
                                        index = {j} 
                                        onSelectOption = {onSelectOption} 
                                        onShowImage = {onShowImage}
                                    />
                                )
                            })
                        }
                        </div>
                    ))
                }
					<div className = 'side'>
						<div className = 'question-tag'>
							<QuestionTag questionList = {questionList}/>
						</div>
						<div className = 'exam-header'>
							<div className = 'timer'>
							{
                                time.enabled &&
                                <Timer duration = {30} finishAttempt = {finishAttempt}/>
                            }
							</div>
							<p className = 'finish' 
                                onClick = {()=>{
                                    setFinishAlert(true)
                                }}
                            >
                                finish attempt
                            </p>
							<div className = 'clear'></div>
						</div>
					</div>
					<PageNavigation 
						page = {examPage} 
						len = {questionList.length} 
						next = {onNextPage} 
						prev = {onPreviousPage}
					/>
				</div>
				<Submit finishAlert = {finishAlert} finishAlertOption = {onSelectFinishOption}/>
			</div>:
            page === "Summary" &&
            <div>
                <div className = 'info'>
                    <div className = 'student-details'>
                        <p>Name: {student.lastname}, {student.firstname}</p>
                        <p>Jamb Registration Number: {student.reg_number}</p>
                    </div>
                    <div className = 'subject-details'>
                        <p>Total Questions: 40</p>
                        <p>Questions Answered: {questionsAnswered}</p>
                        <p>Time Spent: {time.timeSpent}</p>
                    </div>
                </div>
                <div className = 'studentPageLink'>
                    <p onClick = {()=>{
                        dashboardChange()
                    }}>Go to dashboard</p>
                </div>
                <div className = 'Submitted'>
                    <p>SUBMITTED! Your Score Has Been Recorded</p>
                </div>
                <div className = 'footer'>
                    <p>postutme@waltersamservices 2021</p>
                </div>
            </div>
        }
        </div>
    )
}

export default UtmeExam;