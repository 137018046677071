import React from 'react';


const ExamCourse = (props)=>{
	return(
		<div className = 'exam-course box'>
			<h3>Exam Course</h3>
			<select name = 'course'>
				<option value = 'None'>None</option>
				{
					props.courseList.map((course, i)=>{
						return <option key = {i} value = {course}>{course}</option>
					})
				}
				<option value = 'Result'>Result</option>
			</select>
			<input className = 'button' type = 'submit' value = 'Set' onClick = {props.setExamCourse}/>
		</div>
	)
}

export default ExamCourse;