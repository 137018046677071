import React from 'react';

const UploadCourse = (props)=>{
	return(
		<div className = 'upload-course box'>
			<h3>Upload Course</h3>
			<div>
				<input className = 'text' type = 'text' name = 'code' placeholder = 'Course code' /><br/><br/>
				<input className = 'text' type = 'text' name = 'title'  placeholder = 'Title' /><br/><br/>
				<input className = 'text' type = 'text' name = 'unit'  placeholder = 'Unit' /><br/><br/>
				<input className = 'text' type = 'text' name = 'totalquestions'  placeholder = 'Number of questions' /><br/><br/>
				<input className = 'text' type = 'text' name = 'duration'  placeholder = 'duration' /><br/><br/>
				<input className = 'button' type = 'submit' value = 'Upload' onClick = {props.uploadCourse}/>
			</div>
		</div>
	)
}

export default UploadCourse;