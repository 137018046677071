import React, { Component }from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import Portal from './Components/Portal/portal';
import Admin from './Components/Admin/admin';
import Utme from './Utme';


class App extends Component{
  constructor(){
    super();
    this.state = {
      examCourse: ''
    }
  }

  componentDidMount(){
    fetch('https://ws-mock-api.herokuapp.com/currentCourse')
    .then(res=> res.json())
    .then(data=>{
      if(data.coursecode){
        this.setState({examCourse: data.coursecode})
      }
    })
    .catch(err=> console.log('unable to access server'))
  }
  
  render(){
    return(
      <div className="App">
        <Router>
          <Switch>
            <Route exact path="/undergraduate">
              <Portal examCourse = {this.state.examCourse}/>
            </Route>
            <Route exact path="/undergraduate/admin">
              <Admin/>
            </Route>
            <Route path="/postutme">
              <Utme/>
            </Route>
            <Redirect to='/postutme'/>
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;