import React, { Component } from 'react';
import './timer.css';

class Timer extends Component{
	constructor(props){
		super(props)
		this.state = {
			timeLeft : props.duration*60,
			timeString : '',
			interval : ''
		}
		this.tick = this.tick.bind(this);
	}

	componentDidMount(){
		const x = setInterval(this.tick, 1000);
		this.setState({interval : x})
	}
	componentWillUnmount(){
		clearInterval(this.state.interval)
		if(this.state.timeLeft >= 1){
			this.props.finishAttempt(this.state.timeLeft)
		}
	}
	tick(){ 
		const time = this.state.timeLeft - 1;
		this.setState({timeLeft : time});
		const hours = Math.floor((time %(60 * 60 * 24))/(60 * 60)) > 9 ? Math.floor((time %(60 * 60 * 24))/(60 * 60)) : "0"+Math.floor((time %(60 * 60 * 24))/(60 * 60)); 
		const minutes = Math.floor((time % (60 * 60)) / (60)) > 9 ? Math.floor((time % (60 * 60)) / (60)) : "0"+Math.floor((time % (60 * 60)) / (60)); 
		const seconds = Math.floor((time % (60))) > 9 ? Math.floor((time % (60))) : "0"+Math.floor((time % (60)));
		this.setState({timeString: `${hours} : ${minutes} : ${seconds}`})
	    if ((time) < 1) { 
	        clearInterval(this.state.interval);
	        this.props.finishAttempt(this.state.timeLeft)
	    } 
	}

	render(){
		if(this.state.timeLeft <= 10){
			return(
				<div className = 'deadline'>
					<h2>{this.state.timeString}</h2>
				</div>
			)
		}else{
			return(
				<div className = 'normal'>
					<h2>{this.state.timeString}</h2>
				</div>
			)
		}
	}
}

export default Timer;