import React from 'react';
import './submit.css';

const Submit = (props)=>{
	if(props.finishAlert){
		return(
			<div className = "submit">
				<p>Are you sure you want to finish this attempt?</p>
				<div className = 'submit-option'>
					<p className = 'no' onClick = {props.finishAlertOption}>No</p>
					<p className = 'yes' onClick = {props.finishAlertOption}>Yes</p>
				</div>
			</div>
		)
	}else{
		return(
			<div></div>
		)
	}
}

export default Submit;