import React from 'react';


const Verify = (props)=>{
	const verify = (event)=>{
		event.preventDefault()
		const form = event.target
		form.parentNode.firstChild.textContent = ''

		fetch('https://ws-mock-api.herokuapp.com/verify',{
			method: 'post',
			headers: {'content-Type': 'application/json'},
			body: JSON.stringify({
				studentToken: form.firstChild.value
			})
		})
		.then((res)=> res.json())
		.then((data)=>{
			if(data !== 'error'){
				props.registerRouteChange("Register");
				window.scrollTo({top: 0, behaviour: 'smooth'});
			}else{
				form.parentNode.firstChild.textContent = 'Invalid registration code'
			}
		})
		.catch(err=> console.log('unable to access server'))
	}

	return(
		<div>
			<div className = 'curved-header'>
				<div>
					<p className = 'hTxt'><b>WALTERSAM SERVICES</b></p>
					<p className = 'sTxt'>online mock examination</p>
				</div>
			</div>

			<div className = 'studentRegister'>
				<div>
					<h3 className = 'register-header'>Register</h3>
					<div className = 'container'>
						<p className = 'error'></p>
						<form onSubmit = {verify}>
							<input className = 'text' type = 'text' name = 'regcode' placeholder = 'Enter Registration Code' required/><br/><br/>
							<input className = 'button' type = 'submit' value = 'verify'/><br/>
						</form>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Verify;