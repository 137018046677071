import React from 'react';

const Result = (props)=>{
	return(
		<div className = 'result box'>
			<h3>Result</h3>
			<select name = 'course'>
			{
				props.courseList.map((course, i)=>{
					return <option key = {i} value = {course}>{course}</option>
				})
			}
			</select>
			<input className = 'button' type = 'submit' value = 'Get' onClick = {props.result}/>
			{
				props.studentResult.map((student, i)=>{
					return(
						<div key = {i} className = 'brad'>
							<p className = 'text'> S/N: {i}</p>
							<p className = 'details'>Matric number</p>
							<p className = 'text'>{student.matricno}</p>
							<p className = 'details'>Result</p>
							<p className = 'text'>{student.score}</p>
							<p className = 'text'>{student.grade}</p>
							
						</div>
					)
				})
		
			}
		</div>
	)
}
export default Result;