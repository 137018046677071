import React from 'react';
import './header.css';
import logo from '../../../images/wsLogo.ico'

const Header = (props)=>{
	if(props.logout){
		return(
			<div className = 'header'>
				<img src={logo} alt ='logo'/>
				<p className = 'header-text'>waltersam services</p>
				<p className='logout' onClick = {()=>{
					props.onRouteChange('Login')
				}}>Log Out</p>
			</div>
		)
	}else{
		return(
			<div className = 'header'>
				<img src={logo} alt ='logo'/>
				<p className = 'header-text'>waltersam services</p>
				<p className = 'course-title'>course: {props.examCourseObj.title}</p>
			</div>
		)
	}
}

export default Header;