import React from 'react';
import logo from '../../images/wsLogo.jpg';

const UtmeAdminHeading = (props)=>{
	return(
		<div>
			<div className = 'header'>
				<img src={logo} alt ='logo'/>
				<p className = 'header-text'>Admin</p>
				<p className='logout' onClick = {()=>{
					props.onRouteChange()
				}}>Log Out</p>
			</div>
			<div className = 'nav'>
				<p onClick = {()=>{
                        props.changePage('generateToken')
                    }
				}>Generate token</p>
				<p onClick = {()=>{
						props.changePage('studentResults')
					}
				}>Student results</p>
				<p onClick = {()=>{
						props.changePage('allStudents')
					}
				}>All students</p>
				<p onClick = {()=>{
						props.changePage('changePassword')
					}
				}>Change password</p>
				<p onClick = {()=>{
						props.changePage('deleteStudent')
					}
				}>Delete student</p>
				<p onClick = {()=>{
						props.changePage('uploadQuestion')
					}
				}>Upload Question</p>
			</div>
			<p className = 'error'>{props.error}</p>
		</div>
	);
}

export default UtmeAdminHeading;