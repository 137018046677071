import React from 'react';
import {Page, Text, View, Document, StyleSheet, Image} from '@react-pdf/renderer';
import logo from '../../../images/wsLogo.PNG';


const styles = StyleSheet.create({
	page: {
		marginTop: 20,
		marginBottom: 20,
		marginLeft: 25,
		marginRight: 25,
		backgroundColor: '#ffffff',
		fontSize: 12
	},
	container: {
		width: 550,
	},
	result_table: {
		display: 'flex',
		flexDirection: 'row',
		
	},
	table_content: {
		width: 150,
		marginBottom: 10
	},
	header: {
		alignItems: 'center',
		fontWeight: 'normal'
	},
	logo: {
		height: 100,
		width: 100,
		borderRadius: 50
	},
	main: {
		margin: 0,
		paddingTop: 10,
	  	fontSize: 15
	},
	sub: {
		margin: 0,
	  	fontSize: 12
	},
	details: {
	  backgroundColor: '#27ADB0',
	  color: '#FFF',
	  marginTop: 10,
	  marginBottom: 10,
	  paddingTop: 2,
	  paddingBottom: 2,
	  paddingLeft: 4,
	  paddingRight: 4
	},
	content: {
		marginLeft: 10
	}

})
const PdfDocument = (props)=>{
	return(
		<Document>
			<Page size = "A4" style = {styles.page}>
				<View style = {styles.container}>
					<View style = {styles.header}>
						<Image source = {logo} style = {styles.logo}/>
						<Text style = {styles.main}>WALTERSAM SERVICES</Text>
						<Text style = {styles.sub}>mock examination result</Text>
					</View>
					<View>
						<Text style = {styles.details}>Matric number</Text>
						<Text style = {styles.content}>{props.student.matricno}</Text>
						<Text style = {styles.details}>Lastname</Text>
						<Text style = {styles.content}>{props.student.lastname}</Text>
						<Text style = {styles.details}>Firstname</Text>
						<Text style = {styles.content}>{props.student.firstname}</Text>
						<Text style = {styles.details}>Faculty</Text>
						<Text style = {styles.content}>{props.student.faculty}</Text>
						<Text style = {styles.details}>Department</Text>
						<Text style = {styles.content}>{props.student.department}</Text>
						
						
						<Text style = {styles.details}>result</Text>
						<View style = {styles.content}>
							<View style = {styles.result_table}>
								<Text style = {styles.table_content}>Course Code</Text>
								<Text style = {styles.table_content}>Unit</Text>
								<Text style = {styles.table_content}>Score</Text>
								<Text style = {styles.table_content}>Grade</Text>
							</View>
							{
								props.student.result.map((result, i)=>{
									return(
										<View key = {i} style = {styles.result_table}>
											<Text style = {styles.table_content}>{result.code}</Text>
											<Text style = {styles.table_content}>{result.unit}</Text>
											<Text style = {styles.table_content}>{result.score}</Text>
											<Text style = {styles.table_content}>{result.grade}</Text>
										</View>
									)
								})
							}
						</View>
						<Text style = {styles.details}>GPA</Text>
						<Text style = {styles.content}>{props.student.gpa}</Text>
					</View>
				</View>
			</Page>
		</Document>
	)
}

export default PdfDocument;