import React from 'react';
import './diagram.css';


const Diagram = (props)=>{
	return(
		<div className = 'diagram-box'>
			<p 
				className = 'close'
				onClick = {props.onCloseImage}
				>close</p>
			<img src = {props.url} alt = 'diagram'/>
		</div>
	)
}

export default Diagram;