export const orderQuestion = (li, no)=>{
	let newLi = li.slice(0, no)
	let QuestionList = [];
	let subList = [];
	newLi.forEach((question, i)=>{
		question.sAnswer = '';
		question.answered = false;
		subList.push(question);
		if((i+1) % 10 === 0){
			QuestionList.push(subList)
			subList = []
		}
	})
	if(subList[0]){
		QuestionList.push(subList)
	}
	return QuestionList;
}