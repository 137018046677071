import React, { Component }from 'react';
import Exam from './Exam/exam';
import Login from './Login/login';
import StudentRegister from './StudentRegister/studentRegister';
import StudentPage from './StudentPage/studentPage';
import UtmeLoading from '../../UtmeComponents/UtmeLoading/UtmeLoading';

class Portal extends Component{
  constructor(props){
    super(props);
    this.state = {
      route: 'Login',
      student: '',
      examCourseObj: '',
      questionArray: [],
      registerResponse: '',
      loading: false
    }
  }

  onAPIrequest = (value)=>{
    this.setState({loading: value})
  }
  onRouteChange = (route)=>{
    this.setState({route})
  }
  regSuccess = (res)=>{
    this.setState({registerResponse: res})
  }
  loadStudent = (student)=>{
    this.setState({student})
  }

  loadExamCourseObj = ()=>{
    const course = this.state.student.courses.find((courseObj)=>{
      return courseObj.code === this.props.examCourse
    })
    if(course){
      this.setState({examCourseObj: course})
    }
    
  }
  loadQuestion = (questionArray)=>{
    for(let i = questionArray.length - 1; i > 0; i--){
      const j = Math.floor(Math.random()*i)
      const temp = questionArray[i]
      questionArray[i] = questionArray[j]
      questionArray[j] = temp
    }

    this.setState({questionArray})

  }

  updateWritten = (course)=>{
    this.setState(state=>{
      const student = state.student
      student.written.push(course)
      return {student}
    })

  }

  render(){
    if(this.state.route === 'Login'){
      return <Login 
                loadStudent = {this.loadStudent} 
                loadExamCourseObj = {this.loadExamCourseObj} 
                onRouteChange = {this.onRouteChange}
                registerResponse = {this.state.registerResponse}
              />
    }
    else if(this.state.route === 'StudentRegister'){
      return <StudentRegister 
                onRouteChange = {this.onRouteChange}
                regSuccess = {this.regSuccess}
              />
    }
    else if(this.state.route === 'StudentPage'){
      return <>
              {this.state.loading && <UtmeLoading/>}
              <StudentPage 
                examCourse = {this.props.examCourse} 
                student = {this.state.student} 
                onRouteChange = {this.onRouteChange}
                loadQuestion = {this.loadQuestion}
                onAPIrequest = {this.onAPIrequest}
              />
            </>
    }
    else if(this.state.route === 'Exam'){
      return <>
              {this.state.loading && <UtmeLoading/>}
              <Exam 
                student = {this.state.student} 
                examCourseObj = {this.state.examCourseObj} 
                onRouteChange = {this.onRouteChange}
                questionArray = {this.state.questionArray}
                updateWritten = {this.updateWritten}
                onAPIrequest = {this.onAPIrequest}
              />
            </>
    }
  }
}

export default Portal;