import React from 'react';
import './body.css';
import Timer from '../Timer/timer';
import Question from '../Question/question';
import QuestionTag from '../QuestionTag/questionTag';
import PageNavigation from '../PageNavigation/pageNavigation';
import Submit from '../Submit/submit';
import Diagram from '../Diagram/diagram';
import { orderQuestion } from './orderQuestion';


class Body extends React.Component{
	constructor(props){
		super(props);
		this.state = {
			questionList: orderQuestion(props.questionArray, props.examCourseObj.totalquestions),
			page: 0,
			finishAlert: false,
			showImage: false,
			imageUrl: ''
		}
		this.onSelectOption = this.onSelectOption.bind(this)
	}
	onSelectOption(event){
		const form = event.target.parentNode.parentNode.parentNode;
		const questionList = this.state.questionList;
		questionList[this.state.page][event.target.id].sAnswer = form.elements[event.target.name].value;
		questionList[this.state.page][event.target.id].answered = true
		this.setState({questionList});
	}

	onNextPage = ()=>{
		this.setState(state =>{
			const page = state.page + 1;
			return{page}
		});
		window.scrollTo({top: 0, behaviour: 'smooth'});
	}

	onPreviousPage = ()=>{
		this.setState(state =>{
			const page = state.page - 1;
			return{page}
		});
		window.scrollTo({top: 0, behaviour: 'smooth'});
	}

	finishAttempt = ()=>{
		let score = 0;
		this.state.questionList.forEach((page, i)=>{
			page.forEach((question, j)=>{
				if(question.answer === question.sAnswer){
					score++
				}
			})
		})
		this.props.leaveExam(this.props.examCourseObj.code, this.props.examCourseObj.unit, score, this.props.examCourseObj.totalquestions, this.props.student)
	}

	finishAlertOption = (event)=>{
		this.setState({finishAlert: false});
		if(event.target.className === 'yes'){
			this.finishAttempt()
		}
	}

	onShowImage = (url)=>{
		this.setState({showImage: true})
		this.setState({imageUrl: url})
	}

	onCloseImage = ()=>{
		this.setState({showImage: false})
	}

	render(){
		return(
			<div className = 'body-container'>
				{
					this.state.showImage &&
					<Diagram url = {this.state.imageUrl} onCloseImage = {this.onCloseImage} />
				}
				<div className = 'questions'>
					{
						this.state.questionList.map((page, i)=>{
							if(i === this.state.page){
								return(
									<div key = {i} className = {`page${i}`}>
										{
											page.map((question, j)=>{
												return <Question key = {j} question = {question} page = {i} index = {j} onSelectOption = {this.onSelectOption} onShowImage = {this.onShowImage}/>
											})
										}
									</div>
								)	
							}else{
								return(
									<div key = {i} className = {`page${i}`} style = {{display: 'none'}}>
										{
											page.map((question, j)=>{
												return <Question key = {j} question = {question} page = {i} index = {j} onSelectOption = {this.onSelectOption}/>
											})
										}
									</div>
								)
							}		
						})
					}
					<div className = 'side'>
						<div className = 'question-tag'>
							<QuestionTag questionList = {this.state.questionList}/>
						</div>
						<div className = 'exam-header'>
							<div className = 'timer'>
								<Timer duration = {this.props.examCourseObj.duration} finishAttempt = {this.finishAttempt}/>
							</div>
							<p className = 'finish' 
							onClick = {()=>{
								this.setState({finishAlert: true})
							}}>finish attempt</p>
							<div className = 'clear'></div>
						</div>
					</div>
					<PageNavigation 
						page = {this.state.page} 
						len = {this.state.questionList.length} 
						next = {this.onNextPage} 
						prev = {this.onPreviousPage}
					/>
				</div>
				<Submit finishAlert = {this.state.finishAlert} finishAlertOption = {this.finishAlertOption}/>
			</div>
		)
	}
	
}
export default Body;