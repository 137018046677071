import React from 'react';


const Register = (props)=>{

	const register = (event)=>{
		event.preventDefault()
		window.scrollTo({top: 0, behaviour: 'smooth'});
		const registerForm = event.target

		registerForm.parentNode.firstChild.textContent = ''
		
		const courses = []
		const matricno = registerForm.childNodes[0].value
		const password = registerForm.childNodes[3].value
		const lastname = registerForm.childNodes[6].value
		const firstname = registerForm.childNodes[9].value
		const faculty = registerForm.childNodes[12].value
		const department = registerForm.childNodes[15].value
		
		event.target.childNodes[19].childNodes.forEach((course)=>{
			if(course.firstChild.checked){
				courses.push(course.firstChild.name)
			}
		})
		
		fetch('https://ws-mock-api.herokuapp.com/register', {
			method: 'post',
			headers: {'content-Type': 'application/json'},
			body: JSON.stringify({
				matricno, password, lastname, firstname, faculty, department, courses
			})
		})
		.then(res=> res.json())
		.then(data=> {
			if(data === 'success'){
				props.regSuccess('You are registered and can login')
				props.onRouteChange("Login")
				window.scrollTo({top: 0, behaviour: 'smooth'});
			}else{
				registerForm.parentNode.firstChild.textContent = data
			}
		})
		.catch(err=> console.log('unable to access server'))
	}

	return(
		<div>
			<div className = 'curved-header'>
				<div>
					<p className = 'hTxt'><b>WALTERSAM SERVICES</b></p>
					<p className = 'sTxt'>online mock examination</p>
				</div>
			</div>

			<div className = 'studentRegister'>
				<div>
					<h3 className = 'register-header'>Register</h3>
					<div className = 'container'>
						<p className = 'error'></p>
						<form onSubmit = {register}>
							<input className = 'text' type = 'text' name = 'matric' placeholder = 'Matric number' required/><br/><br/>
							<input className = 'text' type = 'password' name = 'password'  placeholder = 'Password' required/><br/><br/>
							<input className = 'text' type = 'name' name = 'lastname'  placeholder = 'Lastname' required/><br/><br/>
							<input className = 'text' type = 'name' name = 'firstname'  placeholder = 'Firstname' required/><br/><br/>
							<input className = 'text' type = 'text' name = 'faculty'  placeholder = 'Faculty' required/><br/><br/>
							<input className = 'text' type = 'text' name = 'department'  placeholder = 'Department' required/><br/><br/>
							<p>Select Courses</p>
							<div name = 'courses'>
								{
									props.courseList.map((course, i)=>{
										return(
											<div key = {i}>
												<input 
													type = 'checkbox' 
													name = {course} 
												/>
												<label htmlFor = {course}>{course}</label><br/>
											</div>
										) 
									})
								}
							</div>
							<br/>
							<input className = 'button' type = 'submit' value = 'Register'/><br/>
						</form>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Register;