import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Switch, Redirect, useHistory} from 'react-router-dom';
import UtmeLogin from './UtmePages/UtmeLogin/UtmeLogin';
import UtmeRgister from './UtmePages/UtmeRegister/UtmeRegister';
import UtmeDashboard from './UtmePages/UtmeDashboard/UtmeDashboard'
import UtmeAdminLogin from './UtmePages/UtmeAdminLogin/UtmeAdminLogin';
import UtmeLoading from './UtmeComponents/UtmeLoading/UtmeLoading';
import UtmeAdminDashboard from './UtmePages/UtmeAdminDashboard/UtmeAdminDashboard';

const ProtectedRoute = ({path, exact, auth, Comp, redirectUrl, ...props})=>{
    if(auth){
        return(
            <Route
                path = {path}
                exact = {exact}
                render = {(otherProps)=>(
                    <Comp
                        {...otherProps}
                        {...props}
                    />
                )}
            />
        )
    }
    return(<Redirect to = {redirectUrl || '/'}/>)    
}

const Utme = ()=>{
    const [loading, setloading] = useState(true);
    const [loggedIn, setLoggedIn] = useState(false);
    const [adminLoggedIn, setAdminLoggedIn] = useState(false)
    const [studentObj, setStudentObj] = useState()

    const history = useHistory()

    const logStudentIn = (student)=>{
        localStorage.setItem('studentId', student.student_id)
        setStudentObj(student)
        setLoggedIn(true)
    }

    const logOut = ()=>{
        localStorage.removeItem('studentId')
        setLoggedIn(false)
        setStudentObj("")
        history.push('/postutme/login')
    }

    const logAdminIn = ()=>{
        setAdminLoggedIn(true)
    }
    
    const logAdminOut = ()=>{
        history.push('/postutme/admin')
        setAdminLoggedIn(false)
    }

    useEffect(()=>{
        const getStudentProfile = (studentId)=>{
            fetch(`${process.env.REACT_APP_API_URL}/student/studentProfile/${studentId}`)
                .then(res=> res.json())
                .then(data=> {
                    if(data.student_id){
                        logStudentIn(data)
                        setloading(false)
                    }else{
                        logOut()
                        console.log('not found')
                        setloading(false)
                    }
                })
                .catch(err=>{
                    console.log(err)
                    console.log('unable to access server')
                    setloading(false)
                })
        }

        const studentId = localStorage.getItem('studentId')

        if(studentId){
            getStudentProfile(studentId)
        }else{
            setloading(false)
        }

    // eslint-disable-next-line
    }, [])

    return(
        loading?
        <UtmeLoading/>
        :
        <Router>
            <Switch>
                <Route
                    path = '/postutme/login'
                    exact
                    render = {(props)=>(
                        <UtmeLogin
                            {...props}
                            logStudentIn = {logStudentIn}
                        />
                    )}
                />
                <Route
                    path = '/postutme/register'
                    exact
                    render = {(props)=>(
                        <UtmeRgister
                            {...props}
                        />
                    )}
                />
                <ProtectedRoute
                    path = '/postutme/dashboard'
                    exact
                    auth = {loggedIn}
                    Comp = {UtmeDashboard}
                    studentDetails = {studentObj}
                    setStudentDetails = {setStudentObj}
                    logOut = {logOut}
                />
                <Route
                    path = '/postutme/admin'
                    exact
                    render = {(props)=>(
                        <UtmeAdminLogin
                            {...props}
                            logAdminIn = {logAdminIn}
                        />
                    )}
                />
                <ProtectedRoute
                    path = '/postutme/admin/dashboard'
                    exact
                    auth = {adminLoggedIn}
                    Comp = {UtmeAdminDashboard}
                    logOut = {logAdminOut}
                />
                {
                    loggedIn? (<Redirect to = '/postutme/dashboard'/>): (<Redirect to='/postutme/login'/>)
                }
            </Switch>
        </Router>
    )
}

export default Utme;