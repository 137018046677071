import React from 'react';
import Header from '../Header/header';
import ExamLink from '../ExamLink/examLink'
import './studentPage.css';
import exam from '../../../images/exam.png';
import read from '../../../images/read.png';
import readmore from '../../../images/readmore.png';

const StudentPage = (props)=>{
	return(
		<div className = 'page-container'>
			<Header logout = {true} onRouteChange = {props.onRouteChange}/>
			<p className = 'welcome'>Welcome, {props.student.lastname} {props.student.firstname}</p>
			<div className = 'column1'>
				<div className = 'user'>
					<div className = 'initial'>
						<p>{props.student.lastname[0]}{props.student.firstname[0]}</p>
					</div>
				</div>
				<div className = 'exam-status'>
					EXAMINATION TIMETABLE COMING SOON
				</div>
			</div>
			<div className = 'column2'>
				<p className = 'details'>Matric number</p>
				<p className = 'text'>{props.student.matricno}</p>
				<p className = 'details'>Faculty</p>
				<p className = 'text'>{props.student.faculty}</p>
				<p className = 'details'>Department</p>
				<p className = 'text'>{props.student.department}</p>
				<p className = 'details'>Registered Courses</p>
				<div className='course-tiles'>
					{
						props.student.courses.map((courseObj, i)=>{
							return(
								<div key = {i} className = "tile">
									<div className = "up">
										<p>{courseObj.code}</p>
									</div>
									<div className = "down">
										<p>course unit: {courseObj.unit}</p>
									</div>
								</div>
							)
						})
					}
				</div>
				
				<p className = 'text'>Waltersam online mock exam aims at preparing student for the main exam with
				   an exam standard computer based test using a proper grading system based on
				   grade point average. You get your results and GPA after completing the exams
				</p>
			</div>
			<ExamLink 
				examCourse = {props.examCourse} 
				student = {props.student} 
				onRouteChange = {props.onRouteChange}
				loadQuestion = {props.loadQuestion}
				onAPIrequest = {props.onAPIrequest}
			/>
			<div className = 'column3'>
				<div className = 'Img'>
					<div className='Img-wrapper wrap1'>
						<img className='im-read' src = {read} alt = 'read'/>
						<p>READ</p>
					</div>
					<div className='Img-wrapper wrap2'>
						<img className='im-exam' src = {exam} alt = 'exam'/>
						<p>TAKE MOCK EXAM</p>
					</div>
					<div className='Img-wrapper wrap3'>
						<img className='im-readmore' src = {readmore} alt = 'readmore'/>
						<p>CONTINUE READING</p>
					</div>
				</div>
			</div>
		</div>
	)
	
}

export default StudentPage;