import React from 'react';
import './pageNavigation.css';


const PageNavigation = (props)=>{
	if(props.len > 1){
		if(props.page === 0){
			return(
				<div>
					<p className = 'next' onClick = {props.next}>NEXT</p>
					<div className = 'clear'></div>
				</div>
			)
		}
		else if(props.page === (props.len-1)){
			return(
				<div>
					<p className = 'previous' onClick = {props.prev}>PREVIOUS</p>
					<div className = 'clear'></div>
				</div>
			)
		}
		else{
			return(
				<div>
					<p className = 'previous' onClick = {props.prev}>PREVIOUS</p>
					<p className = 'next' onClick = {props.next}>NEXT</p>
					<div className = 'clear'></div>
				</div>
			)
		}
	}else{
		return <div></div>
	}
	
	
}

export default PageNavigation;