import React, { Component }from 'react';
import AdminLogin from './AdminLogin/adminLogin';
import AdminProfile from './AdminProfile/adminProfile';

class Admin extends Component{
  constructor(props){
    super(props);
    this.state = {
      route: 'AdminLogin',
      courseList: []
    }
  }

  onRouteChange = (route)=>{
    this.setState({route})
  }

  updateCourseList = (courseList)=>{
    this.setState({courseList})
  }
  render(){
    if(this.state.route === 'AdminLogin'){
      return <AdminLogin updateCourseList = {this.updateCourseList} onRouteChange = {this.onRouteChange}/>
    }
    else if(this.state.route === 'AdminProfile'){
      return <AdminProfile courseList = {this.state.courseList} onRouteChange = {this.onRouteChange}/>
    }
  }
}

export default Admin;