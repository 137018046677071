import React from 'react';
import Latex from 'react-latex'
import './question.css';


const Question = (props)=>{
	const number = props.index+1 > 9? `Question ${props.page + 1}0`: `Question ${props.page}${props.index+1}`;

	return(
		<div className = 'question-container'>
			<h4>{number}</h4>
			<p><Latex output = "html">{props.question.text}</Latex></p>
			{
				props.question.diagram &&
				<p 
					className = 'diagram-link'
					onClick = {()=>{
						props.onShowImage(props.question.diagram)
					}}
				>
					Veiw diagram
				</p>
			}
			<form>
				<div className = 'option'>
					<div className = 'radio'><input type = 'radio' id = {props.index} name = {number} value = 'A' onClick = {props.onSelectOption}/>A</div>
					<p><Latex output = "html">{props.question.optiona}</Latex></p>
				</div>
				<div className = 'option'>
					<div className = 'radio'><input type = 'radio' id = {props.index} name = {number} value = 'B' onClick = {props.onSelectOption}/>B</div>	
					<p><Latex output = "html">{props.question.optionb}</Latex></p>
				</div>
				<div className = 'option'>
					<div className = 'radio'><input type = 'radio' id = {props.index} name = {number} value = 'C' onClick = {props.onSelectOption}/>C</div>
					<p><Latex output = "html">{props.question.optionc}</Latex></p>
				</div>
				<div className = 'option'>
					<div className = 'radio'><input type = 'radio' id = {props.index} name = {number} value = 'D' onClick = {props.onSelectOption}/>D</div>
					<p><Latex output = "html">{props.question.optiond}</Latex></p>
				</div>
			</form>
		</div>

	)
}

export default Question;