import React from 'react';
import './adminLogin.css';

const AdminLogin = (props)=>{
	const focus = (event)=>{
		let parent = event.target.parentNode;
		parent.classList.add('focus');
	}

	const blur = (event)=>{
		let parent = event.target.parentNode;
		if(event.target.value === ""){
			parent.classList.remove('focus');
		}
	}

	const Login = (event)=>{
		event.preventDefault();
		const form = event.target
		form.firstChild.textContent = ''

		const loginFormData = new URLSearchParams();
		for(const pair of new FormData(event.target)){
			loginFormData.append(pair[0], pair[1]);
		}

		fetch('https://ws-mock-api.herokuapp.com/adminLogin', {
			method: 'post',
			body: loginFormData
		})
		.then(response=> response.json())
		.then(data=>{
			if(data !== "there was an error"){
				const courseList = data.map(courseObj=>{
					return courseObj.code
				})

				props.updateCourseList(courseList)
				props.onRouteChange("AdminProfile");
				window.scrollTo({top: 0, behaviour: 'smooth'});
			}else{
				form.firstChild.textContent = 'Invalid details'
			}
		})
		.catch(err=> console.log('unable to access server'))
		
	}
	return(
		<div>
			<div className = 'curved-header'>
				<div>
					<p className = 'hTxt'><b>WALTERSAM SERVICES</b></p>
					<p className = 'sTxt'>online mock examination</p>
				</div>
			</div>
			<div className = 'login'>
				<div>
					<div className = 'login-header'>
						<h3>ADMIN</h3>
					</div>
					<div className = 'login-container'>
						<form onSubmit = {Login}>
							<p className = 'error'></p>
							<div className = 'input-div'>
								<input 
									onFocus = {focus}
									onBlur = {blur}
									className = 'input' 
									type = 'text'
									name = 'username'
									placeholder = 'username'
									required
								/>
							</div>
							<div className = 'input-div'>
								<input 
									onFocus = {focus}
									onBlur = {blur}
									className = 'input' 
									type = 'password'
									name = 'password'
									placeholder = 'password'
									required
								/>
							</div>
							<div>
								<input className = 'btn' type='submit' value ='Login'/>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	)
}

export default AdminLogin;