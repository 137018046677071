import React from 'react';
import './login.css';
import user from '../../../images/login.jpg';

const Login = (props)=>{
	const focus = (event)=>{
		let parent = event.target.parentNode;
		parent.classList.add('focus');
	}

	const blur = (event)=>{
		let parent = event.target.parentNode;
		if(event.target.value === ""){
			parent.classList.remove('focus');
		}
	}

	const Login = (event)=>{
		event.preventDefault();
		const form = event.target
		form.childNodes[0].textContent = ''
		form.childNodes[1].textContent = 'Loading...'

		const loginFormData = new URLSearchParams();
		for(const pair of new FormData(form)){
			loginFormData.append(pair[0], pair[1])
		}

		fetch('https://ws-mock-api.herokuapp.com/login', {
			method: 'post',
			body: loginFormData
		})
		.then(res=> res.json())
		.then(data=>{
			if(data.matricno){
				form.childNodes[1].textContent = ''
				props.loadStudent(data)
				props.loadExamCourseObj()
				props.onRouteChange("StudentPage");
				window.scrollTo({top: 0, behaviour: 'smooth'});
			}else{
				form.firstChild.textContent = 'Invalid Matric number or password'
				form.childNodes[1].textContent = ''
			}
		})
		.catch(err=> console.log('unable to access server'))

		
	}
	return(
		<div>
			<div className = 'curved-header'>
				<div>
					<p className = 'hTxt'><b>WALTERSAM SERVICES</b></p>
					<p className = 'sTxt'>online mock examination</p>
				</div>
			</div>
			<div className = 'login'>
				<div>
					<div className = 'login-header'>
						<img src={user} alt = 'user'/>
					</div>
					<div className = 'login-container'>
						<form onSubmit = {Login}>
							<p className = 'error'></p>
							<p className = 'success'>{props.registerResponse}</p>
							<div className = 'input-div'>
								<input 
									onFocus = {focus}
									onBlur = {blur}
									className = 'input' 
									type = 'text'
									name = 'matricno'
									placeholder = 'matric number'
									required
								/>
							</div>
							<div className = 'input-div'>
								<input 
									onFocus = {focus}
									onBlur = {blur}
									className = 'input' 
									type = 'password'
									name = 'password'
									placeholder = 'password'
									required
								/>
							</div>
							<div>
								<input className = 'btn' type='submit' value ='Login'/>
							</div>
							<a href = "https://api.whatsapp.com/send?phone=2348097550815&text=Hi%2C%20I%20am%20a%20registered%20student%20of%20Waltersam%20Services%20and%20I%20want%20to%20reset%20my%20password.%20%0AMy%20matric%20no%20is">forgot password?</a>
						</form>
						<div className = 'reg'>
							<p className = 'ques'>Not Registered?</p>
							<button 
								className = 'btn'
								onClick = {()=>{
									props.onRouteChange("StudentRegister")
									window.scrollTo({top: 0, behaviour: 'smooth'})
								}}
							>
								Register
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Login;